import 'components/Button/Button.scss';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { Caption } from 'components/Typography';

import React, { CSSProperties, ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  children: ReactNode;
  onClick?: () => void;
  type?: 'primary' | 'secondary' | 'ghost';
  size?: 'L' | 'M' | 'S',
  style?: CSSProperties;
  className?: string;
  icon?: ReactNode;
}

const Button: React.FC<Props> = ({
  children,
  onClick,
  style,
  className,
  size = 'M',
  type = 'primary',
  icon,
}) => {
  const isPatientVersion = useIsPatientVersion();
  const classes = cn('button', `button_${size}`, className, type, { button_patients: isPatientVersion });

  return (
    <button className={classes} type="button" style={style} onClick={onClick}>
      {icon}
      <Caption variant={size}>{children}</Caption>
    </button>
  );
};

export default Button;
