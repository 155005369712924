import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollingEvents = () => {
  const { pathname } = useLocation();
  const [ isPageAtTop, setIsPageAtTop ] = useState<boolean>(true);

  // scroll to top when a navigation event happens
  useEffect(() => {
    const timer = setTimeout(() => window.scrollTo(0, 0), 50);

    return () => clearTimeout(timer);
  }, [ pathname ]);

  useEffect(() => {
    const handleScroll = () => {
      setIsPageAtTop(window.scrollY === 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { isPageAtTop };
};

export default useScrollingEvents;
