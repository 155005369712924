import styles from 'components/Tag/Tag.module.scss';
import { Caption } from 'components/Typography';

import React, { CSSProperties } from 'react';

interface Props {
  children: React.ReactNode;
  style?: CSSProperties;
}

const Tag: React.FC<Props> = ({ children, style }) => {
  return (
    <div className={styles.tag} style={style}>
      <Caption variant="S">{children}</Caption>
    </div>
  );
};

export default Tag;
