import styles from 'pages/GalateaDifference/Publications/Publications.module.scss';
import urls from 'app/consts/routing';
import { createOptionsFromTags, getUniqueTags } from 'app/helpers/catalog';
import { formatDate } from 'app/helpers/dateTime';
import usePaginationParams from 'app/hooks/usePaginationParams';
import usePublications from 'app/hooks/usePublications';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import Tag from 'components/Tag/Tag';
import { Heading } from 'components/Typography';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions';

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Publications: React.FC<Page> = ({ setPageHeader }) => {
  const { t } = useTranslation();
  const { searchParam, sortByParam } = usePaginationParams();
  const { fetchPublications, publications, isLoading } = usePublications();

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: t('menuItems.galateaDifference'), link: urls.galateaDifference },
    { text: t('menuItems.publications') },
  ], [ t ]);

  const sortByOptions = useMemo(() => {
    const tags = getUniqueTags(publications.items);
    const options = createOptionsFromTags(tags);
    options.unshift({ value: '', label: 'All publications' });

    return options;
  }, [ publications.items ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchPublications(sortByParam, searchParam, abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchPublications, sortByParam, searchParam ]);

  useEffect(() => {
    setPageHeader(<PageHeader
      title={t('menuItems.publications')}
      breadcrumbItems={breadcrumbItems}
      rightColumn={<PageHeaderActions sortByOptions={sortByOptions} />}
    />);
  }, [ setPageHeader, breadcrumbItems, t, sortByOptions ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.publications}>
      {publications.items.map(publication => (
        <div className={styles.publications__item} key={publication.id}>
          <Link to={urls.publicationsDetailUrl(publication.meta.slug)} className={styles.publications__itemTitle}>
            <Heading variant="H3">{publication.title}</Heading>
          </Link>

          <div className={styles.publications__meta}>
            <Heading variant="H5">{formatDate(publication.publicationDate)}</Heading>

            <div className={styles.publications__tagsList}>
              {publication.tags.map(tag => <Tag key={tag}>{tag}</Tag>)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Publications;
