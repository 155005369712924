import { sortBy } from 'lodash';

export const STATES: readonly string[] = [ 'Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming' ];

interface Country {
  abbr: string;
  label: string;
  code: string;
  order?: number;
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const COUNTRIES: readonly Country[] = [
  { label: 'Afghanistan', abbr: 'AF', code: '93' },
  { label: 'Alland Islands', abbr: 'AX', code: '358' },
  { label: 'Albania', abbr: 'AL', code: '355' },
  { label: 'Algeria', abbr: 'DZ', code: '213' },
  { label: 'American Samoa', abbr: 'AS', code: '1-684' },
  { label: 'Andorra', abbr: 'AD', code: '376' },
  { label: 'Angola', abbr: 'AO', code: '244' },
  { label: 'Anguilla', abbr: 'AI', code: '1-264' },
  { label: 'Antarctica', abbr: 'AQ', code: '672' },
  { label: 'Antigua and Barbuda', abbr: 'AG', code: '1-268' },
  { label: 'Argentina', abbr: 'AR', code: '54' },
  { label: 'Armenia', abbr: 'AM', code: '374' },
  { label: 'Aruba', abbr: 'AW', code: '297' },
  { label: 'Australia', abbr: 'AU', code: '61' },
  { label: 'Austria', abbr: 'AT', code: '43' },
  { label: 'Azerbaijan', abbr: 'AZ', code: '994' },
  { label: 'Bahamas', abbr: 'BS', code: '1-242' },
  { label: 'Bahrain', abbr: 'BH', code: '973' },
  { label: 'Bangladesh', abbr: 'BD', code: '880' },
  { label: 'Barbados', abbr: 'BB', code: '1-246' },
  { label: 'Belarus', abbr: 'BY', code: '375' },
  { label: 'Belgium', abbr: 'BE', code: '32' },
  { label: 'Belize', abbr: 'BZ', code: '501' },
  { label: 'Benin', abbr: 'BJ', code: '229' },
  { label: 'Bermuda', abbr: 'BM', code: '1-441' },
  { label: 'Bhutan', abbr: 'BT', code: '975' },
  { label: 'Bolivia', abbr: 'BO', code: '591' },
  { label: 'Bosnia and Herzegovina', abbr: 'BA', code: '387' },
  { label: 'Botswana', abbr: 'BW', code: '267' },
  { label: 'Bouvet Island', abbr: 'BV', code: '47' },
  { label: 'Brazil', abbr: 'BR', code: '55' },
  { label: 'British Indian Ocean Territory', abbr: 'IO', code: '246' },
  { label: 'British Virgin Islands', abbr: 'VG', code: '1-284' },
  { label: 'Brunei Darussalam', abbr: 'BN', code: '673' },
  { label: 'Bulgaria', abbr: 'BG', code: '359' },
  { label: 'Burkina Faso', abbr: 'BF', code: '226' },
  { label: 'Burundi', abbr: 'BI', code: '257' },
  { label: 'Cambodia', abbr: 'KH', code: '855' },
  { label: 'Cameroon', abbr: 'CM', code: '237' },
  { label: 'Canada', abbr: 'CA', code: '1' },
  { label: 'Cape Verde', abbr: 'CV', code: '238' },
  { label: 'Cayman Islands', abbr: 'KY', code: '1-345' },
  { label: 'Central African Republic', abbr: 'CF', code: '236' },
  { label: 'Chad', abbr: 'TD', code: '235' },
  { label: 'Chile', abbr: 'CL', code: '56' },
  { label: 'China', abbr: 'CN', code: '86' },
  { label: 'Christmas Island', abbr: 'CX', code: '61' },
  { label: 'Cocos (Keeling) Islands', abbr: 'CC', code: '61' },
  { label: 'Colombia', abbr: 'CO', code: '57' },
  { label: 'Comoros', abbr: 'KM', code: '269' },
  { label: 'Congo, Democratic Republic of the', abbr: 'CG', code: '243' },
  { label: 'Congo, Republic of the', abbr: 'CD', code: '242' },
  { label: 'Cook Islands', abbr: 'CK', code: '682' },
  { label: 'Costa Rica', abbr: 'CR', code: '506' },
  { label: 'Cote d\'Ivoire', abbr: 'CI', code: '225' },
  { label: 'Croatia', abbr: 'HR', code: '385' },
  { label: 'Cuba', abbr: 'CU', code: '53' },
  { label: 'Curacao', abbr: 'CW', code: '599' },
  { label: 'Cyprus', abbr: 'CY', code: '357' },
  { label: 'Czech Republic', abbr: 'CZ', code: '420' },
  { label: 'Denmark', abbr: 'DK', code: '45' },
  { label: 'Djibouti', abbr: 'DJ', code: '253' },
  { label: 'Dominica', abbr: 'DM', code: '1-767' },
  { label: 'Dominican Republic', abbr: 'DO', code: '1-809' },
  { label: 'Ecuador', abbr: 'EC', code: '593' },
  { label: 'Egypt', abbr: 'EG', code: '20' },
  { label: 'El Salvador', abbr: 'SV', code: '503' },
  { label: 'Equatorial Guinea', abbr: 'GQ', code: '240' },
  { label: 'Eritrea', abbr: 'ER', code: '291' },
  { label: 'Estonia', abbr: 'EE', code: '372' },
  { label: 'Ethiopia', abbr: 'ET', code: '251' },
  { label: 'Falkland Islands (Malvinas)', abbr: 'FK', code: '500' },
  { label: 'Faroe Islands', abbr: 'FO', code: '298' },
  { label: 'Fiji', abbr: 'FJ', code: '679' },
  { label: 'Finland', abbr: 'FI', code: '358' },
  { label: 'France', abbr: 'FR', code: '33' },
  { label: 'French Guiana', abbr: 'GF', code: '594' },
  { label: 'French Polynesia', abbr: 'PF', code: '689' },
  { label: 'French Southern Territories', abbr: 'TF', code: '262' },
  { label: 'Gabon', abbr: 'GA', code: '241' },
  { label: 'Gambia', abbr: 'GM', code: '220' },
  { label: 'Georgia', abbr: 'GE', code: '995' },
  { label: 'Germany', abbr: 'DE', code: '49' },
  { label: 'Ghana', abbr: 'GH', code: '233' },
  { label: 'Gibraltar', abbr: 'GI', code: '350' },
  { label: 'Greece', abbr: 'GR', code: '30' },
  { label: 'Greenland', abbr: 'GL', code: '299' },
  { label: 'Grenada', abbr: 'GD', code: '1-473' },
  { label: 'Guadeloupe', abbr: 'GP', code: '590' },
  { label: 'Guam', abbr: 'GU', code: '1-671' },
  { label: 'Guatemala', abbr: 'GT', code: '502' },
  { label: 'Guernsey', abbr: 'GG', code: '44' },
  { label: 'Guinea-Bissau', abbr: 'GW', code: '245' },
  { label: 'Guinea', abbr: 'GN', code: '224' },
  { label: 'Guyana', abbr: 'GY', code: '592' },
  { label: 'Haiti', abbr: 'HT', code: '509' },
  { label: 'Heard Island and McDonald Islands', abbr: 'HM', code: '672' },
  { label: 'Holy See (Vatican City State)', abbr: 'VA', code: '379' },
  { label: 'Honduras', abbr: 'HN', code: '504' },
  { label: 'Hong Kong', abbr: 'HK', code: '852' },
  { label: 'Hungary', abbr: 'HU', code: '36' },
  { label: 'Iceland', abbr: 'IS', code: '354' },
  { label: 'India', abbr: 'IN', code: '91' },
  { label: 'Indonesia', abbr: 'ID', code: '62' },
  { label: 'Iran, Islamic Republic of', abbr: 'IR', code: '98' },
  { label: 'Iraq', abbr: 'IQ', code: '964' },
  { label: 'Ireland', abbr: 'IE', code: '353' },
  { label: 'Isle of Man', abbr: 'IM', code: '44' },
  { label: 'Israel', abbr: 'IL', code: '972' },
  { label: 'Italy', abbr: 'IT', code: '39' },
  { label: 'Jamaica', abbr: 'JM', code: '1-876' },
  { label: 'Japan', abbr: 'JP', code: '81' },
  { label: 'Jersey', abbr: 'JE', code: '44' },
  { label: 'Jordan', abbr: 'JO', code: '962' },
  { label: 'Kazakhstan', abbr: 'KZ', code: '7' },
  { label: 'Kenya', abbr: 'KE', code: '254' },
  { label: 'Kiribati', abbr: 'KI', code: '686' },
  { label: 'Korea, Democratic People\'s Republic of', abbr: 'KP', code: '850' },
  { label: 'Korea, Republic of', abbr: 'KR', code: '82' },
  { label: 'Kosovo', abbr: 'XK', code: '383' },
  { label: 'Kuwait', abbr: 'KW', code: '965' },
  { label: 'Kyrgyzstan', abbr: 'KG', code: '996' },
  { label: 'Lao People\'s Democratic Republic', abbr: 'LA', code: '856' },
  { label: 'Latvia', abbr: 'LV', code: '371' },
  { label: 'Lebanon', abbr: 'LB', code: '961' },
  { label: 'Lesotho', abbr: 'LS', code: '266' },
  { label: 'Liberia', abbr: 'LR', code: '231' },
  { label: 'Libya', abbr: 'LY', code: '218' },
  { label: 'Liechtenstein', abbr: 'LI', code: '423' },
  { label: 'Lithuania', abbr: 'LT', code: '370' },
  { label: 'Luxembourg', abbr: 'LU', code: '352' },
  { label: 'Macao', abbr: 'MO', code: '853' },
  { label: 'Macedonia, the Former Yugoslav Republic of', abbr: 'MK', code: '389' },
  { label: 'Madagascar', abbr: 'MG', code: '261' },
  { label: 'Malawi', abbr: 'MW', code: '265' },
  { label: 'Malaysia', abbr: 'MY', code: '60' },
  { label: 'Maldives', abbr: 'MV', code: '960' },
  { label: 'Mali', abbr: 'ML', code: '223' },
  { label: 'Malta', abbr: 'MT', code: '356' },
  { label: 'Marshall Islands', abbr: 'MH', code: '692' },
  { label: 'Martinique', abbr: 'MQ', code: '596' },
  { label: 'Mauritania', abbr: 'MR', code: '222' },
  { label: 'Mauritius', abbr: 'MU', code: '230' },
  { label: 'Mayotte', abbr: 'YT', code: '262' },
  { label: 'Mexico', abbr: 'MX', code: '52' },
  { label: 'Micronesia, Federated States of', abbr: 'FM', code: '691' },
  { label: 'Moldova, Republic of', abbr: 'MD', code: '373' },
  { label: 'Monaco', abbr: 'MC', code: '377' },
  { label: 'Mongolia', abbr: 'MN', code: '976' },
  { label: 'Montenegro', abbr: 'ME', code: '382' },
  { label: 'Montserrat', abbr: 'MS', code: '1-664' },
  { label: 'Morocco', abbr: 'MA', code: '212' },
  { label: 'Mozambique', abbr: 'MZ', code: '258' },
  { label: 'Myanmar', abbr: 'MM', code: '95' },
  { label: 'Namibia', abbr: 'NA', code: '264' },
  { label: 'Nauru', abbr: 'NR', code: '674' },
  { label: 'Nepal', abbr: 'NP', code: '977' },
  { label: 'Netherlands', abbr: 'NL', code: '31' },
  { label: 'New Caledonia', abbr: 'NC', code: '687' },
  { label: 'New Zealand', abbr: 'NZ', code: '64' },
  { label: 'Nicaragua', abbr: 'NI', code: '505' },
  { label: 'Niger', abbr: 'NE', code: '227' },
  { label: 'Nigeria', abbr: 'NG', code: '234' },
  { label: 'Niue', abbr: 'NU', code: '683' },
  { label: 'Norfolk Island', abbr: 'NF', code: '672' },
  { label: 'Northern Mariana Islands', abbr: 'MP', code: '1-670' },
  { label: 'Norway', abbr: 'NO', code: '47' },
  { label: 'Oman', abbr: 'OM', code: '968' },
  { label: 'Pakistan', abbr: 'PK', code: '92' },
  { label: 'Palau', abbr: 'PW', code: '680' },
  { label: 'Palestine, State of', abbr: 'PS', code: '970' },
  { label: 'Panama', abbr: 'PA', code: '507' },
  { label: 'Papua New Guinea', abbr: 'PG', code: '675' },
  { label: 'Paraguay', abbr: 'PY', code: '595' },
  { label: 'Peru', abbr: 'PE', code: '51' },
  { label: 'Philippines', abbr: 'PH', code: '63' },
  { label: 'Pitcairn', abbr: 'PN', code: '870' },
  { label: 'Poland', abbr: 'PL', code: '48' },
  { label: 'Portugal', abbr: 'PT', code: '351' },
  { label: 'Puerto Rico', abbr: 'PR', code: '1' },
  { label: 'Qatar', abbr: 'QA', code: '974' },
  { label: 'Reunion', abbr: 'RE', code: '262' },
  { label: 'Romania', abbr: 'RO', code: '40' },
  { label: 'Russian Federation', abbr: 'RU', code: '7' },
  { label: 'Rwanda', abbr: 'RW', code: '250' },
  { label: 'Saint Barthelemy', abbr: 'BL', code: '590' },
  { label: 'Saint Helena', abbr: 'SH', code: '290' },
  { label: 'Saint Kitts and Nevis', abbr: 'KN', code: '1-869' },
  { label: 'Saint Lucia', abbr: 'LC', code: '1-758' },
  { label: 'Saint Martin (French part)', abbr: 'MF', code: '590' },
  { label: 'Saint Pierre and Miquelon', abbr: 'PM', code: '508' },
  { label: 'Saint Vincent and the Grenadines', abbr: 'VC', code: '1-784' },
  { label: 'Samoa', abbr: 'WS', code: '685' },
  { label: 'San Marino', abbr: 'SM', code: '378' },
  { label: 'Sao Tome and Principe', abbr: 'ST', code: '239' },
  { label: 'Saudi Arabia', abbr: 'SA', code: '966' },
  { label: 'Senegal', abbr: 'SN', code: '221' },
  { label: 'Serbia', abbr: 'RS', code: '381' },
  { label: 'Seychelles', abbr: 'SC', code: '248' },
  { label: 'Sierra Leone', abbr: 'SL', code: '232' },
  { label: 'Singapore', abbr: 'SG', code: '65' },
  { label: 'Sint Maarten (Dutch part)', abbr: 'SX', code: '1-721' },
  { label: 'Slovakia', abbr: 'SK', code: '421' },
  { label: 'Slovenia', abbr: 'SI', code: '386' },
  { label: 'Solomon Islands', abbr: 'SB', code: '677' },
  { label: 'Somalia', abbr: 'SO', code: '252' },
  { label: 'South Africa', abbr: 'ZA', code: '27' },
  { label: 'South Georgia and the South Sandwich Islands', abbr: 'GS', code: '500' },
  { label: 'South Sudan', abbr: 'SS', code: '211' },
  { label: 'Spain', abbr: 'ES', code: '34' },
  { label: 'Sri Lanka', abbr: 'LK', code: '94' },
  { label: 'Sudan', abbr: 'SD', code: '249' },
  { label: 'Surilabel', abbr: 'SR', code: '597' },
  { label: 'Svalbard and Jan Mayen', abbr: 'SJ', code: '47' },
  { label: 'Swaziland', abbr: 'SZ', code: '268' },
  { label: 'Sweden', abbr: 'SE', code: '46' },
  { label: 'Switzerland', abbr: 'CH', code: '41' },
  { label: 'Syrian Arab Republic', abbr: 'SY', code: '963' },
  { label: 'Taiwan, Province of China', abbr: 'TW', code: '886' },
  { label: 'Tajikistan', abbr: 'TJ', code: '992' },
  { label: 'Thailand', abbr: 'TH', code: '66' },
  { label: 'Timor-Leste', abbr: 'TL', code: '670' },
  { label: 'Togo', abbr: 'TG', code: '228' },
  { label: 'Tokelau', abbr: 'TK', code: '690' },
  { label: 'Tonga', abbr: 'TO', code: '676' },
  { label: 'Trinidad and Tobago', abbr: 'TT', code: '1-868' },
  { label: 'Tunisia', abbr: 'TN', code: '216' },
  { label: 'Turkey', abbr: 'TR', code: '90' },
  { label: 'Turkmenistan', abbr: 'TM', code: '993' },
  { label: 'Turks and Caicos Islands', abbr: 'TC', code: '1-649' },
  { label: 'Tuvalu', abbr: 'TV', code: '688' },
  { label: 'Uganda', abbr: 'UG', code: '256' },
  { label: 'Ukraine', abbr: 'UA', code: '380' },
  { label: 'United Arab Emirates', abbr: 'AE', code: '971' },
  { label: 'United Kingdom', abbr: 'GB', code: '44' },
  { label: 'United Republic of Tanzania', abbr: 'TZ', code: '255' },
  { label: 'USA', abbr: 'US', code: '1', order: 1 },
  { label: 'Uruguay', abbr: 'UY', code: '598' },
  { label: 'US Virgin Islands', abbr: 'VI', code: '1-340' },
  { label: 'Uzbekistan', abbr: 'UZ', code: '998' },
  { label: 'Vanuatu', abbr: 'VU', code: '678' },
  { label: 'Venezuela', abbr: 'VE', code: '58' },
  { label: 'Vietnam', abbr: 'VN', code: '84' },
  { label: 'Wallis and Futuna', abbr: 'WF', code: '681' },
  { label: 'Western Sahara', abbr: 'EH', code: '212' },
  { label: 'Yemen', abbr: 'YE', code: '967' },
  { label: 'Zambia', abbr: 'ZM', code: '260' },
  { label: 'Zimbabwe', abbr: 'ZW', code: '263' },
];

export const USA = COUNTRIES.find(country => country.abbr === 'US')!.label;

export const COUNTRIES_NAMES = sortBy(COUNTRIES, [ 'order' ]).map(country => country.label);
