import 'components/Header/components/SiteVersionSwitcher/SiteVersionSwitcher.scss';
import Context from 'app/consts/context';
import { Caption } from 'components/Typography';

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const SiteVersionSwitcher: React.FC = () => {
  const { t } = useTranslation();
  const { siteVersion, setSiteVersion } = useContext(Context);

  const toggleSiteVersion = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSiteVersion(!e.target.checked ? 'providers' : 'patients');
  };

  return (
    <div className="siteVersionSwitcher">

      <input type="checkbox" checked={siteVersion === 'patients'} className="siteVersionSwitcher__checkbox" onChange={toggleSiteVersion} />

      <span className="siteVersionSwitcher__underlay" />
      <span className="siteVersionSwitcher__knob"><Caption variant="L">{t('general.providers')}</Caption></span>
      <span className="siteVersionSwitcher__knob siteVersionSwitcher__knob_right"><Caption variant="L">{t('general.patients')}</Caption></span>

    </div>
  );
};

export default SiteVersionSwitcher;
