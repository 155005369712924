import 'components/Icon/Icon.scss';

import React, { ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  icon: ReactNode;
  className?: string;
}

const Icon: React.FC<Props> = ({ icon, className }) => {
  const classes = cn('icon', className);

  return <div className={classes}>{icon}</div>;
};

export default Icon;
