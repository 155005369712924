import styles from 'pages/WelcomePage/components/Publications/Publications.module.scss';
import { Body, Heading } from 'components/Typography';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  headerText: string;
  linkTo: string
}

const Publications: React.FC<Props> = ({ headerText, linkTo }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.publications}>

      <div>

        <div className={styles.publications__header}>
          <Heading>{headerText}</Heading>
        </div>

        <div className={styles.publications__list}>

          <Link to="/" className={styles.publications__listItem}>
            <Body variant="M" className={styles.publications__link}>
              Global Biobank Meta-analysis Initiative: Powering genetic discovery across human disease
            </Body>
          </Link>

          <Link to="/" className={styles.publications__listItem}>
            <Body variant="M" className={styles.publications__link}>
              XGMIX: Local-Ancestry Inference with Stacked XGBOOST
            </Body>
          </Link>

        </div>

      </div>

      <div>

        <div className={styles.publications__separator} />

        <Link to={linkTo} className={styles.publications__showAll}>
          <Body className={styles.publications__link} variant="L">{t('welcomePage.showAll')}</Body>
        </Link>

      </div>

    </div>
  );
};

export default Publications;
