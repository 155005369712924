import styles from 'pages/GalateaDifference/Events/Events.module.scss';
import urls from 'app/consts/routing';
import usePaginationParams from 'app/hooks/usePaginationParams';
import useEvents from 'app/hooks/useEvents';
import { getUniqueTags, createOptionsFromTags } from 'app/helpers/catalog';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import { Heading } from 'components/Typography';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions';
import EventListItem from 'pages/GalateaDifference/Events/components/EventListItem/EventListItem';

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const Events: React.FC<Page> = ({ setPageHeader }) => {
  const { t } = useTranslation();
  const { sortByParam } = usePaginationParams();
  const { fetchEvents, events, isLoading } = useEvents();

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: t('menuItems.galateaDifference'), link: urls.galateaDifference },
    { text: t('menuItems.events') },
  ], [ t ]);

  const sortByOptions = useMemo(() => {
    const upcomingTags = getUniqueTags(events.upcomingEvents);
    const pastTags = getUniqueTags(events.pastEvents);
    const allTags = Array.from(new Set([ ...upcomingTags, ...pastTags ]));

    const options = createOptionsFromTags(allTags);
    options.unshift({ value: '', label: 'All Events' });

    return options;
  }, [ events ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchEvents(sortByParam, abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchEvents, sortByParam ]);

  useEffect(() => {
    setPageHeader(<PageHeader
      title={t('menuItems.events')}
      breadcrumbItems={breadcrumbItems}
      rightColumn={<PageHeaderActions sortByOptions={sortByOptions} hideSearch />}
    />);
  }, [ setPageHeader, breadcrumbItems, t, sortByOptions ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.events}>
      <Heading variant="H4">Upcoming</Heading>

      <div className={styles.events__list}>
        {events.upcomingEvents.map(event => <EventListItem event={event} key={event.id} />)}
      </div>

      <Heading variant="H4">Past events</Heading>

      <div className={styles.events__list}>
        {events.pastEvents.map(event => <EventListItem event={event} key={event.id} isPast />)}
      </div>
    </div>
  );
};

export default Events;
