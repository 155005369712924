import 'pages/ProductCatalog/components/Panel/components/PersonalizedReport/PersonalizedReport.scss';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { Body, Caption, Heading, Hero } from 'components/Typography';
import prs1 from 'pages/ProductCatalog/components/Panel/components/PersonalizedReport/assets/prs1.png';
import prs2 from 'pages/ProductCatalog/components/Panel/components/PersonalizedReport/assets/prs2.png';
import prs3 from 'pages/ProductCatalog/components/Panel/components/PersonalizedReport/assets/prs3.png';

import React from 'react';
import cn from 'classnames';

const PersonalizedReport: React.FC = () => {
  const isPatientVersion = useIsPatientVersion();
  const reportItemClasses = cn('personalizedReportItem', { personalizedReportItem_patients: isPatientVersion });

  return (
    <div className="personalizedReport">

      <Heading variant="H5">Your personalized report will visualize your risk in multiple ways: </Heading>

      <Body variant="M" className="personalizedReport__example">For example, PRS risk (or Relative risk) at 65 yo</Body>

      <div className="personalizedReport__items">

        <div className={reportItemClasses}>

          <div className="personalizedReportItem__topLine">
            <Body variant="S">Compared to the <b>reference<br /> population</b>, your PRS places you in the</Body>
            <div className="personalizedReportItem__circle">
              <Caption variant="XS" className="personalizedReportItem__order">th</Caption>
              <Hero variant="L">83</Hero>
              <Caption variant="XS">percentile</Caption>
            </div>
          </div>

          <img src={prs1} alt="Distribution plot" />
          <Caption variant="XS">Distribution of population for the trait.</Caption>

        </div>

        <div className={reportItemClasses}>

          <div className="personalizedReportItem__topLine">
            <Body variant="S">Compared to the <b>average risk in the reference<br /> population,</b> your risk of developing this trait is approximately:</Body>
            <div className="personalizedReportItem__circle">
              <Hero variant="L">1.9</Hero>
              <Caption variant="XS">times higher</Caption>
            </div>
          </div>

          <img src={prs2} alt="Relative Risk plot" />
          <Caption variant="XS">Relative Risk plot representing the percentile of the population with increased risk of developing the trait</Caption>

        </div>

        <div className={reportItemClasses}>

          <div className="personalizedReportItem__topLine">
            <Body variant="S">Considering your <b>biological sex and your ancestry,</b> your chance of developing a trait by the age of 65:</Body>
            <div className="personalizedReportItem__circle personalizedReportItem__circle_withoutPadding">
              <Hero variant="L">17%</Hero>
            </div>
          </div>

          <img src={prs3} alt="Probability risk plot" />
          <Caption variant="XS">Probability risk plot for developing the trait by given age</Caption>

        </div>

      </div>

    </div>
  );
};

export default PersonalizedReport;
