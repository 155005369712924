import 'pages/ProductCatalog/components/Comparing/Comparing.scss';
import urls from 'app/consts/routing';
import { getProductTypeTitle } from 'app/helpers/catalog';
import { getIconByType } from 'app/helpers/icons';
import useInViewRefs from 'app/hooks/useInViewRefs';
import { BreadcrumbItem, SectionItem, Page, SidebarItem } from 'app/types/ProductCatalog';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import ComparingContent from 'pages/ProductCatalog/components/Comparing/components/ComparingContent/ComparingContent';
import useComparing from 'pages/ProductCatalog/components/Comparing/hooks/useComparing';

import React, { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

const Comparing: React.FC<Page> = ({ setPageHeader }) => {
  const { panelId, subsetId } = useParams<{ panelId: string, subsetId: string }>();
  const { fetchComparing, comparing, isLoading } = useComparing();
  const { subset, testingPanel } = comparing;
  const { productType, genesAmount } = testingPanel;
  const doNotDisplayGenes = genesAmount === 0;
  const isCommon = productType === null;
  const isPrs = productType === 'PRS';
  const productTypeTitle = useMemo(() => getProductTypeTitle(isPrs), [ isPrs ]);

  useEffect(() => {
    const abortController = new AbortController();
    if (panelId && subsetId) {
      fetchComparing(panelId, subsetId, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [ fetchComparing, panelId, subsetId ]);

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: 'Products', link: urls.products },
    { text: 'Catalog', link: urls.panels },
    ...(!isCommon ? [ { text: productTypeTitle, link: urls.panelsWithSort(productType) } ] : []),
    { text: testingPanel.title, link: urls.panelDetailUrl(testingPanel.slug) },
    { text: 'Comparing' },
  ], [ testingPanel, isCommon, productType, productTypeTitle ]);

  const inViewRefs = useInViewRefs();
  const firstMenuText = 'Description';
  const secondMenuText = 'Genes';
  const thirdMenuText = 'Specifications';
  const fourthMenuText = 'Limitations';
  const sectionItems: SectionItem[] = [
    { id: firstMenuText, ref: inViewRefs[0].ref },
    { id: secondMenuText, ref: inViewRefs[1].ref, hide: doNotDisplayGenes },
    { id: thirdMenuText, ref: inViewRefs[2].ref },
    { id: fourthMenuText, ref: inViewRefs[3].ref },
  ];
  const sidebarItems: SidebarItem[] = useMemo((): SidebarItem[] => [
    { text: firstMenuText, inView: inViewRefs[0].inView },
    { text: secondMenuText, inView: inViewRefs[1].inView, hide: doNotDisplayGenes },
    { text: thirdMenuText, inView: inViewRefs[2].inView },
    { text: fourthMenuText, inView: inViewRefs[3].inView },
  ], [ inViewRefs, doNotDisplayGenes ]);

  const OrderButton = useMemo(() => (
    <Link to={urls.howItWorksProviders} className="comparing__howToOrder">
      <Button onClick={() => {}} size="L" icon={getIconByType('shopping-cart-add')}>How to order</Button>
    </Link>
  ), []);

  useEffect(() => {
    if (!isLoading) {
      setPageHeader(<PageHeader
        title={`${testingPanel.title} vs ${subset.title}`}
        sidebarItems={sidebarItems}
        breadcrumbItems={breadcrumbItems}
        rightColumn={OrderButton}
      />);
    } else {
      setPageHeader(undefined);
    }
  }, [ setPageHeader, breadcrumbItems, sidebarItems, testingPanel, subset, isLoading, OrderButton ]);

  if (isLoading) {
    return <Loader />;
  }

  return <PageWithSidebar sidebarItems={sidebarItems} content={<ComparingContent panel={testingPanel} subset={subset} sectionItems={sectionItems} />} />;
};

export default Comparing;
