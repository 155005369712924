import { axiosInstance } from 'api/instance';
import { PublicationResponse, PublicationsResponse } from 'api/Publications/types';
import urls from 'api/Publications/urls';

import { AxiosResponse } from 'axios';

export const getPublicationsRequest = (
  sortBy: string | null,
  search: string,
  { signal }: AbortController,
): Promise<AxiosResponse<PublicationsResponse>> => {
  return axiosInstance.get<PublicationsResponse>(urls.publicationsListUrl(sortBy, search), { signal });
};

export const getPublicationRequest = (slug: string, { signal }: AbortController): Promise<AxiosResponse<PublicationResponse>> => {
  return axiosInstance.get<PublicationResponse>(urls.publicationsDetailUrl(slug), { signal });
};
