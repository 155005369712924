import 'components/Sidebar/Sidebar.scss';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { SidebarItem } from 'app/types/ProductCatalog';
import { Heading } from 'components/Typography';

import React, { useEffect, useState } from 'react';
import cn from 'classnames';

interface Props {
  sidebarItems: SidebarItem[];
}

const Sidebar: React.FC<Props> = ({ sidebarItems }) => {
  const [ top, setTop ] = useState<number>(0);
  const headerHeight = document.querySelector('.headerWrapper')?.clientHeight;
  const isPatientVersion = useIsPatientVersion();
  const classes = (inView: boolean) => cn('sidebar__item', { sidebar__item_patients: isPatientVersion, active: inView });

  useEffect(() => {
    if (headerHeight) {
      setTop(headerHeight + 1 + 22); // + header border bottom height + container padding top
    }
  }, [ headerHeight ]);

  const scrollToSection = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      window.scrollTo(0, section.offsetTop + 2);
    }
  };

  return (
    <nav className="sidebar" style={{ top }}>

      {sidebarItems.filter(i => !i.hide).map(i => (
        <span onClick={() => scrollToSection(i.text)} key={i.text}><Heading className={classes(i.inView)} variant="H6">{i.text}</Heading></span>
      ))}

    </nav>
  );
};

export default Sidebar;
