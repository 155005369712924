import styles from 'components/SampleCollectionInstructions/SampleCollectionInstructions.module.scss';
import { Body, Heading } from 'components/Typography';

import React from 'react';

const SampleCollectionInstructions: React.FC = () => {
  return (
    <>
      <Heading variant="H3">Sample Collection Instructions</Heading>
  
      <div className={styles.sampleCollectionInstructions}>
        <Body variant="L" className={styles.sampleCollectionInstructions__text}>
          This protocol explains how to self-collect samples using ORACOLLECT.DX (OCD-100) tubes
        </Body>
  
        <div className={styles.sampleCollectionInstructions__precautions}>
  
          <div className={styles.sampleCollectionInstructions__precautionsImage} />
  
          <div>
  
            <Heading variant="H6" className={styles.sampleCollectionInstructions__precautionsTitle}>
              Collection precautions:
            </Heading>
  
            <Body variant="S">
              Donor should not eat, drink, smoke or chew gum for 30 minutes before collecting a buccal sample.
              Ensure the sponge tip does NOT come into contact with any surface prior to collection.
            </Body>
  
          </div>
  
        </div>
  
      </div>
  
      <div className={styles.sampleCollectionInstructions__items}>
  
        <div className={styles.sampleCollectionInstructions__item}>
          <div className={styles.sampleCollectionInstructions__itemImage} />
          <Body variant="L">
            Open the package and remove the collector without touching the sponge tip. Place the sponge as far back in the mouth as comfortable
            and rub along the lower gums in a back-and-forth motion. Gently rub the gums 10 times. Avoid rubbing the teeth.
          </Body>
        </div>
  
        <div className={styles.sampleCollectionInstructions__item}>
          <div className={styles.sampleCollectionInstructions__itemImage} />
          <Body variant="L">
            Gently repeat rubbing motion on the opposite side of the mouth along the lower gums for an additional 10 times.
          </Body>
        </div>
  
        <div className={styles.sampleCollectionInstructions__item}>
          <div className={styles.sampleCollectionInstructions__itemImage} />
          <Body variant="L">
            Hold the tube upright to prevent the stabilizing liquid inside the tube from spilling.
            Unscrew the blue cap from the collection tube without touching the sponge.
          </Body>
        </div>
  
        <div className={styles.sampleCollectionInstructions__item}>
          <div className={styles.sampleCollectionInstructions__itemImage} />
          <Body variant="L">
            Turn the cap upside down, insert the sponge into the tube and close the cap tightly.
          </Body>
        </div>
  
        <div className={styles.sampleCollectionInstructions__item}>
          <div className={styles.sampleCollectionInstructions__itemImage} />
          <Body variant="L">
            Invert the capped tube and shake vigorously 10 times.
          </Body>
        </div>
  
      </div>
    </>
  );
};

export default SampleCollectionInstructions;
