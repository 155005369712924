import 'components/PageHeader/components/PageHeaderActions.scss';
import MobileSearch from 'components/MobileSearch/MobileSearch';
import Search from 'components/Search/Search';
import Select, { Props as SelectProps } from 'components/Select/Select';

import React from 'react';

interface Props extends SelectProps {
  hideSearch?: boolean;
}

const PageHeaderActions: React.FC<Props> = ({ sortByOptions, hideSearch = false }) => {
  return (
    <div className="pageHeaderActions">
      <Select sortByOptions={sortByOptions} />
      {!hideSearch && (
        <>
          <Search />
          <MobileSearch />
        </>
      )}
    </div>
  );
};

export default PageHeaderActions;
