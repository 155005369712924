import { DateTime } from 'luxon';

export const formatDateTime = (date: string, withSecs = false) => {
  return DateTime.fromISO(String(date)).toFormat(`MM / dd / yyyy h:${withSecs ? 'mm:ssa' : 'mma'}`);
};

export const formatDate = (date: string) => DateTime.fromISO(String(date)).toFormat('MM / dd / yyyy');

export const getDateInfo = (date: string) => {
  const formattedDate = DateTime.fromISO(String(date));

  return {
    day: formattedDate.get('day'),
    month: formattedDate.get('monthLong'),
    year: formattedDate.get('year'),
  };
};
