import { InViewRef } from 'app/types/ProductCatalog';

import { useInView } from 'react-intersection-observer';
import { useEffect, useState, useMemo } from 'react';

const useInViewRefs = (): InViewRef[] => {
  const [ props, setProps ] = useState({ rootMargin: '' });
  const headerHeight = document.querySelector('.headerWrapper')?.clientHeight;

  useEffect(() => {
    if (headerHeight) {
      setProps({ rootMargin: `${-headerHeight}px 0px 0px 0px` });
    }
  }, [ headerHeight ]);

  const { ref: firstRef, inView: firstRefInView } = useInView(props);
  const { ref: secondRef, inView: secondRefInView } = useInView(props);
  const { ref: thirdRef, inView: thirdRefInView } = useInView(props);
  const { ref: forthRef, inView: forthRefInView } = useInView(props);
  const { ref: fifthRef, inView: fifthRefInView } = useInView(props);
  const { ref: sixthRef, inView: sixthRefInView } = useInView(props);
  const shouldShowThirdRef = !secondRefInView && !firstRefInView;
  const shouldShowFourthRef = !thirdRefInView && shouldShowThirdRef;
  const shouldShowFifthRef = !forthRefInView && shouldShowFourthRef;
  const shouldShowSixthRef = !fifthRefInView && shouldShowFifthRef;

  return useMemo(() => [
    { ref: firstRef, inView: firstRefInView },
    { ref: secondRef, inView: secondRefInView && !firstRefInView },
    { ref: thirdRef, inView: thirdRefInView && shouldShowThirdRef },
    { ref: forthRef, inView: forthRefInView && shouldShowFourthRef },
    { ref: fifthRef, inView: fifthRefInView && shouldShowFifthRef },
    { ref: sixthRef, inView: sixthRefInView && shouldShowSixthRef },
  ], [
    firstRef,
    firstRefInView,
    secondRef,
    secondRefInView,
    thirdRef,
    thirdRefInView,
    shouldShowThirdRef,
    forthRef,
    forthRefInView,
    shouldShowFourthRef,
    fifthRef,
    fifthRefInView,
    shouldShowFifthRef,
    sixthRef,
    sixthRefInView,
    shouldShowSixthRef,
  ]);
};

export default useInViewRefs;
