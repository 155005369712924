import 'components/Breadcrumb/Breadcrumb.scss';
import Context from 'app/consts/context';
import { BreadcrumbItem } from 'app/types/ProductCatalog';
import { Body } from 'components/Typography';

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<Props> = ({ items }) => {
  const { siteVersion } = useContext(Context);

  return (
    <nav className="breadcrumb">
      <Body>{siteVersion[0].toUpperCase() + siteVersion.slice(1)}</Body>
      {items.map((i) => {
        return (
          <div key={i.text} className="breadcrumb__item">
            <div className="breadcrumb__arrow"><div /></div>
            {i.link ? (
              <Link to={i.link} className="breadcrumb__link">
                <Body>{i.text}</Body>
              </Link>
            ) : (
              <Body>{i.text}</Body>
            )}
          </div>
        );
      })}
    </nav>
  );
};

export default Breadcrumb;
