import { getTestingPanelsRequest } from 'api/TestingPanels/api';
import { TestingPanelsResponse } from 'api/TestingPanels/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useState } from 'react';

const useTestingPanels = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();
  const [ testingPanels, setTestingPanels ] = useState<TestingPanelsResponse>({
    meta: { totalCount: 0 },
    items: [],
  });

  const fetchTestingPanels = useCallback((sortBy: string | null, search: string, abortController: AbortController) => {
    setIsLoading(true);
    setError(undefined);

    getTestingPanelsRequest(sortBy, search, abortController)
      .then(({ data }) => {
        setTestingPanels(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          setError(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, []);

  return { testingPanels, isLoading, error, fetchTestingPanels };
};

export default useTestingPanels;
