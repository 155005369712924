import { getProductRequest } from 'api/Products/api';
import { ProductResponse } from 'api/Products/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useState } from 'react';

const useProduct = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();
  const [ product, setProduct ] = useState<ProductResponse>({
    id: 0,
    meta: { slug: '', locale: 'en' },
    title: '',
    productType: null,
    productDetails: [ {
      type: '',
      value: {
        detailsImage: '',
        detailsInfo: '',
      },
      id: '',
    } ],
    primaryButtonText: '',
    secondaryButtonText: '',
    body: [],
    testingPanels: [],
    genesAmount: 0,
  });

  const fetchProduct = useCallback((slug: string, abortController: AbortController) => {
    setIsLoading(true);
    setError(undefined);

    getProductRequest(slug, abortController)
      .then(({ data }) => {
        setProduct(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          setError(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, []);

  return { product, isLoading, error, fetchProduct };
};

export default useProduct;
