import 'components/Typography/Hero/Hero.scss';

import React, { CSSProperties } from 'react';
import cn from 'classnames';

interface Props {
  children: React.ReactNode;
  variant?: 'L' | 'M' | 'S';
  style?: CSSProperties;
  className?: string;
}

const Hero: React.FC<Props> = ({ children, style, variant = 'S', className }) => {
  const classes = cn('hero', `hero_${variant}`, className);

  return (
    <span className={classes} style={style}>{children}</span>
  );
};

export default Hero;
