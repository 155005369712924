import 'pages/WelcomePage/components/AncestryAwareSolutions/AncestryAwareSolutions.scss';
import ancestryAwareVideo from 'app/assets/ancestry_aware.mp4';
import urls from 'app/consts/routing';
import { getIconByType } from 'app/helpers/icons';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import Button from 'components/Button/Button';
import { Heading } from 'components/Typography';
import VideoBackground from 'components/VideoBackground/VideoBackground';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';

const AncestryAwareSolutions: React.FC = () => {
  const { t } = useTranslation();
  const isPatientVersion = useIsPatientVersion();

  return (
    <div className="ancestryAwareSolutions">

      <div className="ancestryAwareSolutions__header">

        <div className={cn('ancestryAwareSolutions__dna', { ancestryAwareSolutions__dna_patients: isPatientVersion })}>
          {getIconByType('dna')}
        </div>

      </div>

      <Heading variant="H1" className="ancestryAwareSolutions__heading">{t('welcomePage.ancestryAware')}</Heading>

      <div className="ancestryAwareSolutions__hero">{t('welcomePage.geneticDiversity')}</div>

      <Link to={urls.panelsWithSort('PRS')} className="ancestryAwareSolutions__button">
        <Button size="L">{t('general.readMore')}</Button>
      </Link>

      <div className="ancestryAwareSolutions__subtract" />

      <VideoBackground video={ancestryAwareVideo} className="ancestryAwareSolutions__video" />

    </div>
  );
};

export default AncestryAwareSolutions;
