import { getEventsRequest } from 'api/Events/api';
import { EventsResponse } from 'api/Events/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useState } from 'react';

const useEvents = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();
  const [ events, setEvents ] = useState<EventsResponse>({
    upcomingEvents: [],
    pastEvents: [],
  });

  const fetchEvents = useCallback((sortBy: string | null, abortController: AbortController) => {
    setIsLoading(true);
    setError(undefined);

    getEventsRequest(sortBy, abortController)
      .then(({ data }) => {
        setEvents(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          setError(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, []);

  return { events, isLoading, error, fetchEvents };
};

export default useEvents;
