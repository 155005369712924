const eventsUrl = 'events/';
const eventsListUrl = (sortBy: string | null) => {
  return [
    `${eventsUrl}?`,
    sortBy && `&tags=${sortBy}`,
  ].join('');
};
const eventsDetailUrl = (slug: string) => `${eventsUrl}${slug}/`;

export default { eventsListUrl, eventsDetailUrl };
