import styles from 'pages/WelcomePage/components/About/About.module.scss';
import { ReactComponent as Logo } from 'app/assets/Logo.svg';
import { Body, Hero } from 'components/Typography';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const About: React.FC = () => {
  const { t } = useTranslation();
  const textArray = t('welcomePage.about').split(' ');

  return (
    <div className={styles.about}>

      <div className={styles.about__header}>

        <div className={styles.about__text}>
          <Hero variant="S">{textArray[0]}</Hero>
          <Hero variant="M">{textArray[1]} {textArray[2]}</Hero>
        </div>

        <Logo className={styles.about__logo} />

      </div>

      <div className={styles.about__list}>

        <Link to="/" className={styles.about__listItem}>
          <Body variant="L" className={styles.about__link}>{t('menuItems.aboutUs')}</Body>
        </Link>

        <Link to="/" className={styles.about__listItem}>
          <Body variant="L" className={styles.about__link}>{t('menuItems.leadershipTeam')}</Body>
        </Link>

        <Link to="/" className={styles.about__listItem}>
          <Body variant="L" className={styles.about__link}>{t('menuItems.events')}</Body>
        </Link>

        <Link to="/" className={styles.about__listItem}>
          <Body variant="L" className={styles.about__link}>{t('menuItems.compliance')}</Body>
        </Link>

        <Link to="/" className={styles.about__listItem}>
          <Body variant="L" className={styles.about__link}>{t('menuItems.licenses')}</Body>
        </Link>
      </div>

    </div>
  );
};

export default About;
