import 'components/Forms/components/FormControls/FormControl.scss';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { FormSelect as FormSelectType } from 'components/Forms/types';
import { Caption } from 'components/Typography';

import React from 'react';
import cn from 'classnames';
import { FieldValues } from 'react-hook-form';

const FormSelect = <T extends FieldValues>({
  name,
  label,
  register,
  errors,
  options,
  className,
  required,
}: FormSelectType<T>) => {
  const isPatientVersion = useIsPatientVersion();
  const hasError = required && !!errors[name];
  const labelClasses = cn('formControl', className);
  const selectClasses = cn(
    'formControl__input',
    { formControl__input_patients: isPatientVersion, formControl__input_invalid: hasError },
  );

  return (
    <label htmlFor={name} className={labelClasses}>
      <Caption variant="XS">{label}</Caption>

      <select
        className={selectClasses}
        id={name}
        {...register(name, { required: required ? `${label} is required` : false })}
        aria-invalid={hasError}
        defaultValue=""
      >
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <option value="" hidden={name === 'country'} />
        {options.map(o => <option key={o} value={o}>{o}</option>)}
      </select>

      {hasError && <Caption className="formControl__error" variant="XS">{errors[name]?.message as string}</Caption>}
    </label>
  );
};

export default FormSelect;
