import 'pages/ProductCatalog/components/Panel/components/PanelContent/PanelContent.scss';
import { Subset as SubsetType } from 'api/TestingPanels/types';
import Subset from 'components/Subset/Subset';
import { Body, Heading } from 'components/Typography';
import AssayInformation from 'pages/ProductCatalog/components/Panel/components/AssayInformation/AssayInformation';
import Specifications from 'pages/ProductCatalog/components/Panel/components/Specifications/Specifications';
import { PanelPage } from 'pages/ProductCatalog/components/Panel/components/PanelContent/types';

import React from 'react';

const TrioPanelContent: React.FC<PanelPage> = ({ sectionItems, panel }) => {
  const {
    description,
    specifications,
    assayInformation,
    methodologies,
    subsets,
    meta: { slug },
  } = panel;

  return (
    <div className="panelContent">

      <div id={sectionItems[0].id} ref={sectionItems[0].ref} className="panelContent__section">
        <Heading variant="H4">Description</Heading>

        <Body variant="M">{description}</Body>
      </div>

      <div id={sectionItems[1].id} ref={sectionItems[1].ref} className="panelContent__section">
        <Heading variant="H4">Specifications</Heading>
        <Specifications specifications={specifications} methodologies={methodologies} />
      </div>

      <div id={sectionItems[2].id} ref={sectionItems[2].ref} className="panelContent__section">
        <AssayInformation assayInformation={assayInformation} />
      </div>

      <div id={sectionItems[3].id} ref={sectionItems[3].ref} className="panelContent__section">
        <Heading variant="H4">Subsets ({subsets.length})</Heading>

        {subsets.map((subset: SubsetType) => <Subset subset={subset} panelSlug={slug} key={subset.title} />)}
      </div>

    </div>
  );
};

export default TrioPanelContent;
