import 'pages/GalateaDifference/About/components/AboutLink/AboutLink.scss';
import { getIconByType } from 'app/helpers/icons';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { Icon } from 'app/types/Icons';
import { Heading } from 'components/Typography';

import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

interface Props {
  text: string;
  icon: Icon;
  linkTo: string;
}

const AboutLink: React.FC<Props> = ({ text, icon, linkTo }) => {
  const isPatientVersion = useIsPatientVersion();

  return (
    <Link to={linkTo} className={cn('aboutLink', { aboutLink_patients: isPatientVersion })}>
      {getIconByType(icon)}
      <Heading variant="H6">{text}</Heading>
    </Link>
  );
};

export default AboutLink;
