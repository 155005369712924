import { Option } from 'app/types/ProductCatalog';

export const getProductTypeTitle = (isPrs: boolean) => {
  if (isPrs) {
    return 'Health Risk Scoring';
  } else {
    return 'Trio Analysis';
  }
};

interface HasTags {
  tags: string[];
}

export const getUniqueTags = (data: HasTags[]): string[] => {
  const tags = data.reduce((total, currentValue) => {
    currentValue.tags.map(t => total.push(t));
    return total;
  }, [] as string[]);

  const uniqueTags = new Set<string>(tags);

  return Array.from(uniqueTags);
};

export const createOptionsFromTags = (tags: string[]): Option[] => {
  return tags.map(t => ({ value: t, label: t }));
};
