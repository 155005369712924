import Context from 'app/consts/context';

import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const ProviderRoot: React.FC = () => {
  const { setSiteVersion } = useContext(Context);

  useEffect(() => {
    setSiteVersion('providers', false);
  });

  return <Outlet />;
};

export default ProviderRoot;
