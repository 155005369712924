import { getEventRequest } from 'api/Events/api';
import { Event } from 'api/Events/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useState } from 'react';

const usePublications = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();
  const [ event, setEvent ] = useState<Event>({
    id: 0,
    title: '',
    shortDescription: '',
    startDate: '',
    endDate: '',
    addressState: '',
    addressCity: '',
    addressPlace: '',
    address: '',
    zoom: 1,
    location: '',
    eventUrl: '',
    tags: [],
    body: [],
    meta: {
      slug: '',
      locale: 'en',
    },
  });

  const fetchEvent = useCallback((publicationSlug: string, abortController: AbortController) => {
    setIsLoading(true);
    setError(undefined);

    getEventRequest(publicationSlug, abortController)
      .then(({ data }) => {
        setEvent(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          setError(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, []);

  return { event, isLoading, error, fetchEvent };
};

export default usePublications;
