import styles from 'pages/GalateaDifference/Events/components/EventListItem/EventListItem.module.scss';
import { EventItem } from 'api/Events/types';
import urls from 'app/consts/routing';
import { getDateInfo } from 'app/helpers/dateTime';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import Tag from 'components/Tag/Tag';
import { Caption, Heading, Hero } from 'components/Typography';

import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

interface Props {
  event: EventItem;
  isPast?: boolean;
}

const EventListItem: React.FC<Props> = ({ event, isPast }) => {
  const isPatients = useIsPatientVersion();
  const startDateInfo = getDateInfo(event.startDate);
  const endDateInfo = getDateInfo(event.endDate);
  const classes = cn(styles.eventListItem, { [styles.eventListItem_patients]: isPatients, [styles.eventListItem_past]: isPast });

  return (
    <Link to={urls.eventsDetailUrl(event.meta.slug)} className={classes}>
  
      <div className={styles.eventListItem__info}>
  
        <div className={styles.eventListItem__dates}>
  
          <div className={styles.eventListItem__days}>
            <Hero variant="M">{startDateInfo.day}</Hero>
            <Heading variant="H6">→</Heading>
            <Hero variant="M">{endDateInfo.day}</Hero>
          </div>
  
          <Heading variant="H6">{startDateInfo.month}</Heading>
  
          <Heading variant="H6">{startDateInfo.year}</Heading>
  
        </div>
  
        <div className={styles.eventListItem__title}>
          <Heading variant="H3">{event.title}</Heading>
          <Heading variant="H6" className={styles.eventListItem__address}>{event.addressCity}, {event.addressState}</Heading>
        </div>
  
      </div>
  
      <div className={styles.eventListItem__meta}>
  
        <div className={styles.eventListItem__tagsList}>
          {event.tags.map(tag => <Tag key={tag}>{tag}</Tag>)}
        </div>
  
        <Caption className={styles.eventListItem__description}>{event.shortDescription}</Caption>
  
      </div>
  
    </Link>
  );
};

export default EventListItem;
