const calculateWidth = (currentElement: Element, tempSelect: Element) => {
  const text = currentElement.querySelector('option:checked')?.textContent;
  const tempOption = tempSelect.children[0];
  const optionText = tempOption.firstChild;

  if (optionText) {
    tempOption.removeChild(optionText);
  }

  if (text) {
    tempOption.append(text);
    tempSelect.setAttribute('style', 'display: block');
    currentElement.setAttribute('style', `width: ${tempSelect.clientWidth}px`);
    tempSelect.setAttribute('style', 'display: none');
  }
};

export default calculateWidth;
