export const mainColor = '#03122E';

export const providers500 = '#51ABB0';

export const patients500 = '#2D86CA';

export const grayscale50 = '#FFF';
export const grayscale200 = '#EFEFEF';
export const grayscale300 = '#BDBDBD';
export const grayscale400 = '#989898';
