import { getTestingPanelRequest } from 'api/TestingPanels/api';
import { TestingPanel } from 'api/TestingPanels/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useState } from 'react';

const usePanel = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();
  const [ panel, setPanel ] = useState<TestingPanel>({
    id: 0,
    meta: { slug: '', locale: 'en' },
    title: '',
    productType: null,
    description: '',
    specifications: [],
    assayInformation: '',
    ancestryInformation: '',
    disordersTested: '',
    legalDisclaimer: null,
    physiciansGuide: [],
    genesAmount: 0,
    genes: [],
    methodologiesAmount: 0,
    methodologies: [],
    subsets: [],
    sampleReport: '',
  });

  const fetchPanel = useCallback((slug: string, abortController: AbortController) => {
    setIsLoading(true);
    setError(undefined);

    getTestingPanelRequest(slug, abortController)
      .then(({ data }) => {
        setPanel(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          setError(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, []);

  return { panel, isLoading, error, fetchPanel };
};

export default usePanel;
