import { ProductType } from 'app/types/Wagtail';

const products = '/products';
const productDetail = `${products}/:productId`;
const panels = `${products}/panels`;
const panelsWithSort = (sortBy: ProductType) => `${panels}/?sortBy=${sortBy}`;
const panelDetail = `${panels}/:panelId`;
const panelDetailUrl = (panelId: string) => `${panels}/${panelId}`;
const subsetComparing = `${products}/comparing/:panelId/:subsetId`;
const subsetComparingUrl = (panelId: string, subsetId: string) => `${products}/comparing/${panelId}/${subsetId}/`;
const trioAnalysis = `${products}/trio-analysis`;
const healthRiskScoring = `${products}/health-risk-scoring`;

const providers = '/providers';
const howItWorksProviders = `${providers}/how-it-works`;
const orderTest = `${providers}/order-test`;
const resources = `${providers}/resources`;

const patients = '/patients';
const howItWorksPatients = `${patients}/how-it-works`;
const submitSample = `${patients}/submit-sample`;
const faq = `${patients}/faq`;

const galateaDifference = '/galatea-difference';
const publications = `${galateaDifference}/publications`;
const publicationsDetail = `${publications}/:publicationSlug`;
const publicationsDetailUrl = (publicationSlug: string) => `${publications}/${publicationSlug}`;
const events = `${galateaDifference}/events`;
const eventsDetail = `${events}/:eventSlug`;
const eventsDetailUrl = (publicationSlug: string) => `${events}/${publicationSlug}`;

const urls = {
  products,
  productDetail,
  panels,
  panelsWithSort,
  panelDetail,
  panelDetailUrl,
  subsetComparing,
  subsetComparingUrl,
  trioAnalysis,
  healthRiskScoring,
  providers,
  howItWorksProviders,
  orderTest,
  resources,
  patients,
  howItWorksPatients,
  submitSample,
  faq,
  galateaDifference,
  publications,
  publicationsDetail,
  publicationsDetailUrl,
  events,
  eventsDetail,
  eventsDetailUrl,
};

export default urls;
