import 'components/Subset/Subset.scss';
import { Subset as SubsetType } from 'api/TestingPanels/types';
import { grayscale400 } from 'app/consts/colors';
import urls from 'app/consts/routing';
import { renderIcon } from 'app/helpers/icons';
import { pluralize } from 'app/helpers/pluralize';
import Button from 'components/Button/Button';
import { Body, Caption, Heading } from 'components/Typography';

import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  subset: SubsetType;
  panelSlug: string;
}

const Subset: React.FC<Props> = ({ subset, panelSlug }) => {
  const { slug, title, genes, icon, shortDescription } = subset;
  const navigate = useNavigate();
  const goToComparing = () => navigate(urls.subsetComparingUrl(panelSlug, slug));

  return (
    <div className="subset">

      <div className="subset__left">

        {icon && renderIcon(icon)}

        <div className="subset__info">

          <Heading variant="H6" className="subset__title">{title}</Heading>

          {shortDescription && <Body style={{ color: grayscale400, marginBottom: 6 }}>{shortDescription}</Body>}

          {genes.length > 0 && <Caption variant="XS" style={{ color: grayscale400 }}>{pluralize(genes.length, 'gene')}</Caption>}

        </div>

      </div>

      <Button onClick={goToComparing} size="S" type="ghost" className="subset__button">Compare</Button>

    </div>
  );
};

export default Subset;
