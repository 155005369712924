import 'pages/ProductCatalog/components/Panel/components/RightSidebar/RightSidebar.scss';
import { Body, Heading } from 'components/Typography';

import React, { useEffect } from 'react';
import cn from 'classnames';

interface Props {
  title: string;
  text: string;
  opened: boolean;
  onClose: () => void;
}

const RightSidebar: React.FC<Props> = ({ title, text, opened, onClose }) => {
  useEffect(() => {
    if (opened) {
      document.querySelector('body')?.style.setProperty('overflow', 'hidden');
    } else {
      document.querySelector('body')?.style.setProperty('overflow', 'auto');
    }
  }, [ opened ]);

  return (
    <>
      {opened && <div className="overlay" />}

      <div className={cn('rightSidebar', { rightSidebar_opened: opened })}>

        <div className="rightSidebar__title">
          <Heading variant="H3">{title}</Heading>

          <span className="rightSidebar__close" onClick={onClose} />
        </div>

        <div className="rightSidebar__content">
          <Body variant="S" style={{ whiteSpace: 'pre-line' }}>{text}</Body>
        </div>
      </div>
    </>
  );
};

export default RightSidebar;
