import styles from 'pages/GalateaDifference/Publications/components/Publication/Publication.module.scss';
import urls from 'app/consts/routing';
import { formatDate } from 'app/helpers/dateTime';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import BodyItem from 'components/BodyItem/BodyItem';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import Tag from 'components/Tag/Tag';
import { Body, Heading } from 'components/Typography';
import usePublication from 'pages/GalateaDifference/Publications/components/Publication/hooks/usePublication';

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const Publication: React.FC<Page> = ({ setPageHeader }) => {
  const { publicationSlug } = useParams<{ publicationSlug: string }>();
  const { t } = useTranslation();
  const { fetchPublication, publication, isLoading } = usePublication();

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: t('menuItems.galateaDifference'), link: urls.galateaDifference },
    { text: t('menuItems.publications'), link: urls.publications },
    { text: publication.title },
  ], [ t, publication.title ]);

  useEffect(() => {
    const abortController = new AbortController();
    if (publicationSlug) {
      fetchPublication(publicationSlug, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [ fetchPublication, publicationSlug ]);

  useEffect(() => {
    if (!isLoading) {
      setPageHeader(<PageHeader title={t('galateaDifference.galateaPublication')} breadcrumbItems={breadcrumbItems} />);
    } else {
      setPageHeader(undefined);
    }
  }, [ setPageHeader, breadcrumbItems, t, isLoading ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.publication}>
      <div className={styles.publication__leftColumn}>
        <Heading variant="H5" className={styles.publication__date}>{formatDate(publication.publicationDate)}</Heading>

        <Heading variant="H6">{t('galateaDifference.authors')}</Heading>

        <Body variant="M" className={styles.publication__authors}>{publication.authors}</Body>

        <Heading variant="H6">{t('galateaDifference.tags')}</Heading>

        <div className={styles.publication__tags}>
          {publication.tags.map(tag => <Tag key={tag}>{tag}</Tag>)}
        </div>
      </div>

      <div>
        <Heading variant="H2" className={styles.publication__title}>{publication.title}</Heading>
        {publication.body.map(item => <BodyItem bodyItem={item} key={item.id} />)}
      </div>
    </div>
  );
};

export default Publication;
