import 'pages/WelcomePage/components/LookingForSection/LookingForSection.scss';
import { ReactComponent as Arrow } from 'app/assets/Arrow.svg';
import Context from 'app/consts/context';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { Hero } from 'components/Typography';

import React, { MouseEvent, FocusEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const LookingForSection: React.FC = () => {
  const { t } = useTranslation();
  const { setSiteVersion } = useContext(Context);
  const isPatientVersion = useIsPatientVersion();
  const text = !isPatientVersion ? t('welcomePage.lookingForPatientSection') : t('welcomePage.lookingForProviderSection');
  const textArray = text.split(' ');

  // prevent animation on page load event
  const onFirstMouseOver = (e: MouseEvent<HTMLDivElement> | FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.classList.contains('animated')) {
      e.currentTarget.classList.add('animated');
    }
  };

  const toggleSiteVersion = () => {
    setSiteVersion(isPatientVersion ? 'providers' : 'patients');
  };

  return (
    <div className="lookingForSection" onMouseOver={onFirstMouseOver} onFocus={onFirstMouseOver} onClick={toggleSiteVersion}>
      <div className={cn('lookingForSection__bg', { lookingForSection__bg_patients: isPatientVersion })}>

        <div className="lookingForSection__sampleKit" />

        <div className="lookingForSection__text">
          <Hero>{textArray[0]} {textArray[1]}</Hero>
          <Hero variant="M">{textArray[2]}</Hero>
          <Hero variant="M">{textArray[3]}</Hero>
        </div>

        <Arrow className="lookingForSection__arrow" />

      </div>
    </div>
  );
};

export default LookingForSection;
