import { HubspotFormField } from 'api/Hubspot/types';

export const getHubspotFields = (data: object) => {
  const fields: HubspotFormField[] = [];
  Object.entries(data).forEach(([ key, value ]) => {
    fields.push({
      objectTypeId: '0-1',
      name: key,
      value,
    });
  });

  return fields;
};
