import { SiteVersion } from 'app/consts/context';
import { getSiteVersionItem, setSiteVersionItem } from 'app/helpers/localStorage';

import { useCallback, useEffect, useState, MutableRefObject } from 'react';
import { useNavigate } from 'react-router-dom';

const useSetSiteVersion = (isMainPage: boolean, containerRef: MutableRefObject<HTMLElement | null>) => {
  const navigate = useNavigate();
  const siteVersionItem = getSiteVersionItem();
  const initValue = (siteVersionItem as SiteVersion) || 'providers';
  const [ siteVersion, setSiteVersion ] = useState<SiteVersion>(initValue);

  const setContainerVersionClass = useCallback(() => {
    if (containerRef && containerRef.current) {
      const { classList } = containerRef.current;
      if (siteVersion === 'patients') {
        classList.add('container_patients');
      } else {
        classList.remove('container_patients');
      }
    }
  }, [ siteVersion, containerRef ]);

  useEffect(() => {
    setContainerVersionClass();
  }, [ setContainerVersionClass ]);

  const toggleSiteVersion = useCallback((version: SiteVersion, redirect = true) => {
    setSiteVersion(version);
    setSiteVersionItem(version);

    if (isMainPage && containerRef && containerRef.current) {
      const { classList } = containerRef.current;
      if (classList.contains('fadeInRight')) {
        classList.remove('fadeInRight');
        classList.add('fadeInLeft');
      } else if (classList.contains('fadeInLeft')) {
        classList.remove('fadeInLeft');
        classList.add('fadeInRight');
      } else {
        classList.add('fadeInRight');
      }
    } else if (redirect) {
      navigate('/');
    }
  }, [ isMainPage, navigate, setSiteVersion, containerRef ]);

  return { setSiteVersion: toggleSiteVersion, siteVersion };
};

export default useSetSiteVersion;
