import 'pages/WelcomePage/WelcomePage.scss';
import { grayscale400 } from 'app/consts/colors';
import urls from 'app/consts/routing';
import { Page } from 'app/types/ProductCatalog';
import {
  About,
  Publications,
  News,
  Action,
  FullTestsCatalog,
  LookingForSection,
  AncestryAwareSolutions,
  RelentlessCommitment,
} from 'pages/WelcomePage/components';
import { Body, Caption, Hero } from 'components/Typography';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const WelcomePage: React.FC<Page> = ({ setPageHeader }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setPageHeader(undefined);
  }, [ setPageHeader ]);

  return (
    <>
      <div className="welcomePage__title">
        <Hero variant="L">{t('welcomePage.precisionHealth')}</Hero>
        <Body variant="L" style={{ marginTop: 22 }}>{t('welcomePage.reimagined')}</Body>
      </div>

      <Caption variant="L" style={{ textTransform: 'uppercase', color: grayscale400 }}>{t('welcomePage.ourProducts')}</Caption>

      <div className="welcomePage__ourProducts">

        <FullTestsCatalog />

        <div className="welcomePage__actions">
          <Action title={t('general.trioAnalysis')} text={t('welcomePage.increaseDiagnostic')} link={urls.trioAnalysis} type="TRIO" />

          <Action title={t('general.healthRiskScoring')} text={t('welcomePage.accessMore')} link={urls.healthRiskScoring} type="PRS" />
        </div>

        <LookingForSection />

      </div>

      <AncestryAwareSolutions />

      <RelentlessCommitment />

      <div className="welcomePage__company">
        <Caption variant="L" style={{ textTransform: 'uppercase', color: grayscale400 }}>{t('welcomePage.company')}</Caption>

        <div className="welcomePage__about">

          <About />

          <div className="welcomePage__publications">
            <Publications headerText={t('welcomePage.ourPublications')} linkTo={urls.publications} />

            <News headerText={t('welcomePage.galateaInNews')} linkTo="/" />
          </div>

        </div>

      </div>
    </>
  );
};

export default WelcomePage;
