import { axiosInstance } from 'api/instance';

import { camelizeKeys } from 'humps';

const setup = () => {
  axiosInstance.interceptors.response.use(
    response => ({
      ...response,
      data: camelizeKeys(response.data),
    }),
    err => Promise.reject(err),
  );
};

export default setup;
