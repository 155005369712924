import styles from 'pages/Provider/Resources/Resources.module.scss';
import useInViewRefs from 'app/hooks/useInViewRefs';
import { SectionItem, Page, SidebarItem } from 'app/types/ProductCatalog';
import DocumentsList from 'components/DocumentsList/DocumentsList';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import { Heading } from 'components/Typography';
import useResources from 'pages/Provider/Resources/hooks/useResources';

import React, { useEffect, useMemo } from 'react';

const Resources: React.FC<Page> = ({ setPageHeader }) => {
  const { fetchResources, resources, isLoading } = useResources();
  const inViewRefs = useInViewRefs();
  const firstMenuText = 'Requisition Forms';
  const secondMenuText = 'Physician Guides';
  const sectionItems: SectionItem[] = [
    { id: firstMenuText, ref: inViewRefs[0].ref },
    { id: secondMenuText, ref: inViewRefs[1].ref },
  ];
  const sidebarItems: SidebarItem[] = useMemo(() => [
    { text: firstMenuText, inView: inViewRefs[0].inView },
    { text: secondMenuText, inView: inViewRefs[1].inView },
  ], [ inViewRefs ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchResources(abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchResources ]);

  const renderResourcesContent = () => {
    return (
      <>
        <div id={sectionItems[0].id} ref={sectionItems[0].ref}>

          <Heading variant="H3" className={styles.resources__title}>{firstMenuText}</Heading>

          <DocumentsList documents={resources.forms} />

        </div>

        <div id={sectionItems[1].id} ref={sectionItems[1].ref}>

          <Heading variant="H3" className={styles.resources__title}>{secondMenuText}</Heading>

          <DocumentsList documents={resources.guides} />
        </div>
      </>
    );
  };

  useEffect(() => setPageHeader(<PageHeader sidebarItems={sidebarItems} />), [ setPageHeader, sidebarItems ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.resources}>

      <PageWithSidebar sidebarItems={sidebarItems} content={renderResourcesContent()} />

    </div>
  );
};

export default Resources;
