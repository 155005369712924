import styles from 'components/DocumentsList/DocumentsList.module.scss';
import { Document } from 'api/Documents/types';
import DownloadFile from 'components/DownloadFile/DownloadFile';

import React from 'react';

interface Props {
  documents: Document[];
}

const DocumentsList: React.FC<Props> = ({ documents }) => {
  return (
    <div className={styles.documentsList}>

      {documents.map((guide: Document) => <DownloadFile document={guide} key={guide.title} />)}

    </div>
  );
};

export default DocumentsList;
