import 'components/Typography/Heading/Heading.scss';

import React, { CSSProperties } from 'react';
import cn from 'classnames';

interface Props {
  children: React.ReactNode;
  variant?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6';
  style?: CSSProperties;
  className?: string;
}

const Heading: React.FC<Props> = ({ children, style, variant = 'H4', className }) => {
  const props = {
    className: cn('heading', `heading_${variant}`, className),
    style,
  };

  const render = () => {
    switch (variant) {
      case 'H1':
        return <h1 {...props}>{children}</h1>;
      case 'H2':
        return <h2 {...props}>{children}</h2>;
      case 'H3':
        return <h3 {...props}>{children}</h3>;
      case 'H4':
        return <h4 {...props}>{children}</h4>;
      case 'H5':
        return <h5 {...props}>{children}</h5>;
      case 'H6':
        return <h6 {...props}>{children}</h6>;
    }
  };

  return render();
};

export default Heading;
