import 'pages/GalateaDifference/About/components/LatestEvents/LatestEvents.scss';
import { EventItem } from 'api/Events/types';
import urls from 'app/consts/routing';
import { getDateInfo } from 'app/helpers/dateTime';
import useEvents from 'app/hooks/useEvents';
import Loader from 'components/Loader/Loader';
import { Body, Heading } from 'components/Typography';

import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LatestEvents: React.FC = () => {
  const { t } = useTranslation();
  const { fetchEvents, events, isLoading } = useEvents();

  useEffect(() => {
    const abortController = new AbortController();
    fetchEvents('', abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchEvents ]);

  const eventList: EventItem[] = useMemo(() => {
    let eventItems: EventItem[];
    eventItems = events.upcomingEvents.slice(0, 3);

    if (eventItems.length < 3) {
      eventItems = [ ...eventItems, ...events.pastEvents.slice(0, 3 - eventItems.length) ];
    }

    return eventItems;
  }, [ events ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="latestEvents">
      <div className="latestEvents__topLine">
        <Heading variant="H4">{t('galateaDifference.latestEvents')}</Heading>

        <Link to={urls.events}><Heading variant="H6">{t('welcomePage.showAll')}</Heading></Link>
      </div>

      <div className="latestEvents__events">

        {eventList.map((event) => {
          const startDateInfo = getDateInfo(event.startDate);
          const endDateInfo = getDateInfo(event.endDate);

          return (
            <div className="latestEvent" key={event.id}>
              <Heading variant="H6">
                {startDateInfo.day} → {endDateInfo.day} {startDateInfo.month} {startDateInfo.year}
              </Heading>
              <Link to={urls.eventsDetailUrl(event.meta.slug)}>
                <Heading variant="H5">
                  {event.title}
                </Heading>
              </Link>
              <Body variant="S">{event.addressCity}, {event.addressState}</Body>
            </div>
          );
        })}

      </div>

    </div>
  );
};

export default LatestEvents;
