import 'index.scss';

import { createContext } from 'react';
import '@fontsource-variable/plus-jakarta-sans';

export type SiteVersion = 'providers' | 'patients';

type SiteVersionState = {
  siteVersion: SiteVersion;
  setSiteVersion: (siteVersion: SiteVersion, redirect?: boolean) => void;
};

const Context = createContext<SiteVersionState>({
  siteVersion: 'providers',
  setSiteVersion: () => {},
});

export default Context;
