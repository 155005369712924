import styles from 'pages/GalateaDifference/Events/components/Event/components/AddressAndDate.module.scss';
import { Event } from 'api/Events/types';
import { getDateInfo } from 'app/helpers/dateTime';
import { Caption, Heading } from 'components/Typography';

import React from 'react';

interface Props {
  event: Event
}

const AddressAndDate: React.FC<Props> = ({ event }) => {
  const startDateInfo = getDateInfo(event.startDate);
  const endDateInfo = getDateInfo(event.endDate);

  return (
    <div className={styles.addressAndDate}>
      <div>
        <Heading variant="H4">{event.addressCity}</Heading>
        <Caption variant="XS">{event.addressState}</Caption>
      </div>
      <div>
        <Heading variant="H4">{startDateInfo.day} - {endDateInfo.day}</Heading>
        <Caption variant="XS">{startDateInfo.month} {startDateInfo.year}</Caption>
      </div>
    </div>
  );
};

export default AddressAndDate;
