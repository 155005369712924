import enTranslations from 'app/translations/en.json';
import esTranslations from 'app/translations/es.json';

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const i18n = {
  resources: {
    en: enTranslations,
    es: esTranslations,
  },
  defaultNS: 'translations',
  lng: 'en',
  fallbackLng: 'en',
  supportedLngs: [ 'en', 'es' ],
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
};

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(i18n);
