import 'components/Forms/components/FormControls/FormControl.scss';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { FormInput as FormInputType } from 'components/Forms/types';
import { Caption } from 'components/Typography';

import React from 'react';
import cn from 'classnames';
import { FieldValues } from 'react-hook-form';

const FormInput = <T extends FieldValues>({
  name,
  label,
  register,
  errors,
  placeholder,
  className,
}: FormInputType<T>) => {
  const isPatientVersion = useIsPatientVersion();
  const labelClasses = cn('formControl', className);
  const inputClasses = cn(
    'formControl__input',
    { formControl__input_patients: isPatientVersion, formControl__input_invalid: !!errors[name] },
  );

  return (
    <label htmlFor={name} className={labelClasses}>
      <Caption variant="XS">{label}</Caption>

      <input
        className={inputClasses}
        id={name}
        {...register(name, { required: `${label} is required` })}
        placeholder={placeholder}
        aria-invalid={!!errors[name]}
      />

      {!!errors[name] && <Caption className="formControl__error" variant="XS">{errors[name]?.message as string}</Caption>}
    </label>
  );
};

export default FormInput;
