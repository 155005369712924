import 'components/Gene/Gene.scss';
import { Caption } from 'components/Typography';

import React from 'react';

interface Props {
  code: string;
}

const Gene: React.FC<Props> = ({ code }) => {
  return (
    <div className="gene">
      <Caption variant="M">{code}</Caption>
    </div>
  );
};

export default Gene;
