import 'components/DownloadFile/DownloadFile.scss';
import { Document } from 'api/Documents/types';
import { grayscale400 } from 'app/consts/colors';
import { renderIcon } from 'app/helpers/icons';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { Body, Caption, Heading } from 'components/Typography';

import React from 'react';
import cn from 'classnames';

interface Props {
  document: Document;
}

const DownloadFile: React.FC<Props> = ({ document }) => {
  const isPatientVersion = useIsPatientVersion();
  const { title, description, documentUrl } = document;
  const fileName = documentUrl.split('/').slice(-1)[0];

  return (
    <div className="downloadFile">

      {renderIcon('file', 'downloadFile__visible')}
      {renderIcon('arrow-down', 'downloadFile__hidden')}

      <div className="downloadFile__info">

        <Heading className={cn('downloadFile__title', { downloadFile__title_patients: isPatientVersion })} variant="H6">{title}</Heading>

        <Body className="downloadFile__description downloadFile__visible" style={{ color: grayscale400 }}>{description}</Body>

        <div className="downloadFile__description downloadFile__hidden">
          <a href={documentUrl} target="_blank" rel="noopener noreferrer">
            <Caption variant="XS">
              Download {fileName}
            </Caption>
          </a>
        </div>

      </div>

    </div>
  );
};

export default DownloadFile;
