import 'components/Header/Header.scss';
import { ReactComponent as HeaderLogo } from 'app/assets/HeaderLogo.svg';
import useScrollingEvents from 'app/hooks/useScrollingEvents';
import LanguageSwitcher from 'components/Header/components/LanguageSwitcher/LanguageSwitcher';
import SiteVersionSwitcher from 'components/Header/components/SiteVersionSwitcher/SiteVersionSwitcher';
import TopMenu from 'components/Header/components/TopMenu/TopMenu';

import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

interface Props {
  pageHeader?: React.ReactNode;
}

const Header: React.FC<Props> = ({ pageHeader }) => {
  const { isPageAtTop } = useScrollingEvents();
  const headerWrapperClassName = cn('headerWrapper', { headerWrapper_inMotion: !isPageAtTop });

  return (
    <div className={headerWrapperClassName}>
      <header className="header">

        <div className="header__content">

          <Link className="header__logoWrapper" to="/">
            <HeaderLogo className="header__logo" />
          </Link>

          <SiteVersionSwitcher />

          <TopMenu />

        </div>

        <LanguageSwitcher />

      </header>

      {pageHeader}
    </div>
  );
};

export default Header;
