import { postSubmitFormRequest } from 'api/Hubspot/api';
import { HubspotFormField } from 'api/Hubspot/types';
import urls from 'api/Hubspot/urls';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { getHubspotFields } from 'components/Forms/helpers';
import { AccessFormValues } from 'components/Forms/AccessForm';

import { SubmitHandler } from 'react-hook-form';
import { useState } from 'react';

const useSubmitAccessForm = () => {
  const [ result, setResult ] = useState<string>('');

  const submitForm: SubmitHandler<AccessFormValues> = (data) => {
    setResult('');
    const fields: HubspotFormField[] = getHubspotFields(data);
    console.log(fields);

    postSubmitFormRequest(urls.accessCatalogFormUrl, fields)
      .then(({ data }) => {
        setResult(data.inlineMessage || 'The form was submitted successfully');
      })
      .catch((reason) => {
        setResult(getErrorFromPromiseReason(reason));
      });
  };

  return { submitForm, result };
};

export default useSubmitAccessForm;
