import styles from 'pages/Patient/SubmitSample/SubmitSample.module.scss';
import useInViewRefs from 'app/hooks/useInViewRefs';
import { SectionItem, Page, SidebarItem } from 'app/types/ProductCatalog';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import SampleCollectionInstructions from 'components/SampleCollectionInstructions/SampleCollectionInstructions';
import { Body, Heading } from 'components/Typography';

import React, { useEffect, useMemo } from 'react';

const SubmitSample: React.FC<Page> = ({ setPageHeader }) => {
  const inViewRefs = useInViewRefs();
  const firstMenuText = 'Shipping';
  const secondMenuText = 'Collection Protocol';
  const sectionItems: SectionItem[] = [
    { id: firstMenuText, ref: inViewRefs[0].ref },
    { id: secondMenuText, ref: inViewRefs[1].ref },
  ];
  const sidebarItems: SidebarItem[] = useMemo(() => [
    { text: firstMenuText, inView: inViewRefs[0].inView },
    { text: secondMenuText, inView: inViewRefs[1].inView },
  ], [ inViewRefs ]);

  const renderSubmitSampleContent = () => {
    return (
      <>
        <div id={sectionItems[0].id} ref={sectionItems[0].ref}>

          <Heading variant="H3" className={styles.submitSample__title}>Sample Shipping Instructions</Heading>

          <Body variant="L">
            Our high complexity molecular laboratory is located at our headquarters at<br />
            <b>14350 Commerce Way, Miami Lakes, FL 33016.</b>
            <br /><br />
            <b>
              All biospecimens must be shipped to this address for sequencing and analysis, regardless of whether the collection was administered at a physician
              location or self administered by the patient at home.
            </b>
            <br /><br />
            The Galatea Bio DNA kit will include collection sample collection instructions (copy of instruction you can see if you’ll scrolling down) for how to
            administer retrieval and safe repackaging of your sample using the ORACOLLECT.DX (OCD-100) tubes. The kit will also be accompanied by a prepaid
            shipping label back to our headquarters. If you do not have one please contact: <a href="mailto:info@galatea.bio">info@galatea.bio</a>
          </Body>

        </div>

        <div className={styles.submitSample__separator} />

        <div id={sectionItems[1].id} ref={sectionItems[1].ref}>
          <SampleCollectionInstructions />
        </div>
      </>
    );
  };

  useEffect(() => setPageHeader(<PageHeader sidebarItems={sidebarItems} />), [ setPageHeader, sidebarItems ]);

  return (
    <div className={styles.submitSample}>

      <PageWithSidebar sidebarItems={sidebarItems} content={renderSubmitSampleContent()} />

    </div>
  );
};

export default SubmitSample;
