import { axiosInstance } from 'api/instance';
import { Document, FormsAndGuidesResponse } from 'api/Documents/types';
import urls from 'api/Documents/urls';

import { AxiosResponse } from 'axios';

export const getFormsAndGuidesRequest = ({ signal }: AbortController): Promise<AxiosResponse<FormsAndGuidesResponse>> => {
  return axiosInstance.get<FormsAndGuidesResponse>(urls.documentsListUrl('forms_guides'), { signal });
};

export const getBuiltInDocsRequest = ({ signal }: AbortController): Promise<AxiosResponse<Document[]>> => {
  return axiosInstance.get<Document[]>(urls.documentsListUrl('builtin'), { signal });
};
