import 'pages/WelcomePage/components/RelentlessCommitment/RelentlessCommitment.scss';
import urls from 'app/consts/routing';
import { getIconByType } from 'app/helpers/icons';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import Button from 'components/Button/Button';
import { Body, Heading } from 'components/Typography';
import frame1 from 'pages/WelcomePage/components/RelentlessCommitment/assets/frame1.png';
import frame2 from 'pages/WelcomePage/components/RelentlessCommitment/assets/frame2.png';
import frame3 from 'pages/WelcomePage/components/RelentlessCommitment/assets/frame3.png';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';

const RelentlessCommitment: React.FC = () => {
  const { t } = useTranslation();
  const isPatientVersion = useIsPatientVersion();

  return (
    <div className={cn('relentlessCommitment', { relentlessCommitment_patients: isPatientVersion })}>

      <div className="relentlessCommitment__content">
        <Heading variant="H1" className="relentlessCommitment__title">{t('welcomePage.relentlessCommitment')}</Heading>

        <Body variant="S" className="relentlessCommitment__body">{t('welcomePage.truthInScience')}</Body>

        <Link to={urls.howItWorksProviders} className="relentlessCommitment__button">
          <Button icon={getIconByType('play-circle')} size="L">{t('welcomePage.getStartedToday')}</Button>
        </Link>
      </div>

      <div className="relentlessCommitment__images">
        <img src={frame1} alt="image1" className="relentlessCommitment__image relentlessCommitment__image1" />
        <img src={frame2} alt="image2" className="relentlessCommitment__image relentlessCommitment__image2" />
        <img src={frame3} alt="frame" className="relentlessCommitment__frame" />
      </div>

    </div>
  );
};

export default RelentlessCommitment;
