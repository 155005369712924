import 'components/PageWithSidebar/PageWithSidebar.scss';
import { SidebarItem } from 'app/types/ProductCatalog';
import Sidebar from 'components/Sidebar/Sidebar';

import React, { ReactNode } from 'react';

interface Props {
  sidebarItems: SidebarItem[];
  content: ReactNode;
}

const PageWithSidebar: React.FC<Props> = ({ sidebarItems, content }) => {
  return (
    <div className="pageWithSidebar">

      <div className="pageWithSidebar__sidebar">
        <Sidebar sidebarItems={sidebarItems} />
      </div>

      <div className="pageWithSidebar__content">
        {content}
      </div>

    </div>
  );
};

export default PageWithSidebar;
