import 'components/MobileSearch/MobileSearch.scss';
import { getIconByType } from 'app/helpers/icons';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import usePaginationParams from 'app/hooks/usePaginationParams';

import React, { KeyboardEvent, useState } from 'react';
import cn from 'classnames';

const MobileSearch: React.FC = () => {
  const isPatientVersion = useIsPatientVersion();
  const { searchParam, sortByParam, setFilter } = usePaginationParams();
  const [ value, setValue ] = useState<string>(searchParam);

  const submitFilter = () => setFilter({ search: value, sortBy: sortByParam });

  const onEnterKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      submitFilter();
    }
  };

  const showInput = () => {
    document.querySelector('.mobileSearch__input')!.classList.add('expanded');
    document.querySelector('.mobileSearch__icon_cancel')!.classList.add('expanded');
    document.querySelector('.mobileSearch__icon_search')!.classList.add('expanded');
  };

  const hideInput = () => {
    document.querySelector('.mobileSearch__input')!.classList.remove('expanded');
    document.querySelector('.mobileSearch__icon_cancel')!.classList.remove('expanded');
    document.querySelector('.mobileSearch__icon_search')!.classList.remove('expanded');
  };

  return (
    <div className={cn('mobileSearch', { mobileSearch_patients: isPatientVersion })}>
      <input
        className="mobileSearch__input"
        value={value}
        type="search"
        onChange={e => setValue(e.target.value)}
        onKeyDown={onEnterKeyDown}
      />

      <div className="mobileSearch__icon mobileSearch__icon_search" onClick={showInput}>{getIconByType('search')}</div>
      <div className="mobileSearch__icon mobileSearch__icon_cancel" onClick={hideInput}>{getIconByType('cancel-circle')}</div>
    </div>
  );
};

export default MobileSearch;
