import styles from 'components/BodyItem/BodyItem.module.scss';
import { BodyItem as BodyItemProps } from 'app/types/Wagtail';

import React from 'react';

interface Props {
  bodyItem: BodyItemProps;
}

const BodyItem: React.FC<Props> = ({ bodyItem }) => {
  if (bodyItem.type === 'image') {
    return (
      <a href={bodyItem.value} target="_blank" rel="noopener noreferrer" className={styles.bodyItem}>
        <img src={bodyItem.value} alt={bodyItem.id} />
      </a>
    );
  } else {
    /* eslint-disable-next-line react/no-danger */
    return <div dangerouslySetInnerHTML={{ __html: bodyItem.value }} className={styles.bodyItem} />;
  }
};

export default BodyItem;
