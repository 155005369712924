import 'pages/ProductCatalog/components/Panel/Panel.scss';
import { grayscale400 } from 'app/consts/colors';
import urls from 'app/consts/routing';
import { getProductTypeTitle } from 'app/helpers/catalog';
import { getIconByType } from 'app/helpers/icons';
import useInViewRefs from 'app/hooks/useInViewRefs';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import { Caption, Heading } from 'components/Typography';
import CommonPanelContent from 'pages/ProductCatalog/components/Panel/components/PanelContent/CommonPanelContent';
import PRSPanelContent from 'pages/ProductCatalog/components/Panel/components/PanelContent/PRSPanelContent';
import TrioPanelContent from 'pages/ProductCatalog/components/Panel/components/PanelContent/TrioPanelContent';
import usePanel from 'pages/ProductCatalog/components/Panel/hooks/usePanel';
import usePanelContent from 'pages/ProductCatalog/components/Panel/hooks/usePanelContent';

import React, { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

const Panel: React.FC<Page> = ({ setPageHeader }) => {
  const { panelId } = useParams<{ panelId: string }>();
  const { fetchPanel, panel, isLoading } = usePanel();
  const { productType, title, subsets, methodologiesAmount, genesAmount } = panel;
  const inViewRefs = useInViewRefs();
  const { sidebarItems, sectionItems } = usePanelContent(inViewRefs, panel);
  const isCommon = productType === null;
  const isPrs = productType === 'PRS';
  const productTypeTitle = useMemo(() => getProductTypeTitle(isPrs), [ isPrs ]);

  useEffect(() => {
    const abortController = new AbortController();
    if (panelId) {
      fetchPanel(panelId, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [ fetchPanel, panelId ]);

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: 'Products', link: urls.products },
    { text: 'Catalog', link: urls.panels },
    ...(!isCommon ? [ { text: productTypeTitle, link: urls.panelsWithSort(productType) } ] : []),
    { text: title },
  ], [ title, isCommon, productType, productTypeTitle ]);

  const PanelMeta = useMemo(() => (
    <div className="panel__actions">
      {!isPrs && (
        <div className="panel__block">
          <Heading variant="H4">{subsets.length}</Heading>
          <Caption variant="XS" style={{ color: grayscale400 }}>Subsets</Caption>
        </div>
      )}

      {!isCommon && (
        <div className="panel__block">
          <Heading variant="H4">{methodologiesAmount}</Heading>
          <Caption variant="XS" style={{ color: grayscale400 }}>Methodologies</Caption>
        </div>
      )}

      {isCommon && (
        <div className="panel__block">
          <Heading variant="H4">{genesAmount}</Heading>
          <Caption variant="XS" style={{ color: grayscale400 }}>Genes in total</Caption>
        </div>
      )}
      <Link to={urls.howItWorksProviders} className="panel__howToOrder">
        <Button onClick={() => {}} size="L" icon={getIconByType('shopping-cart-add')}>How to order</Button>
      </Link>
    </div>
  ), [ methodologiesAmount, subsets, genesAmount, isCommon, isPrs ]);

  const renderPanelContent = () => {
    if (isCommon) {
      return <CommonPanelContent sectionItems={sectionItems} panel={panel} />;
    } else if (isPrs) {
      return <PRSPanelContent sectionItems={sectionItems} panel={panel} />;
    } else {
      return <TrioPanelContent sectionItems={sectionItems} panel={panel} />;
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setPageHeader(<PageHeader title={title} sidebarItems={sidebarItems} breadcrumbItems={breadcrumbItems} rightColumn={PanelMeta} />);
    } else {
      setPageHeader(undefined);
    }
  }, [ setPageHeader, breadcrumbItems, PanelMeta, sidebarItems, title, isLoading ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="panel">

      <PageWithSidebar sidebarItems={sidebarItems} content={renderPanelContent()} />

    </div>
  );
};

export default Panel;
