import Context from 'app/consts/context';

import { useContext } from 'react';

const useIsPatientVersion = () => {
  const { siteVersion } = useContext(Context);

  return siteVersion === 'patients';
};

export default useIsPatientVersion;
