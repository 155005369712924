import { axiosInstance } from 'api/instance';
import { Event, EventsResponse } from 'api/Events/types';
import urls from 'api/Events/urls';

import { AxiosResponse } from 'axios';

export const getEventsRequest = (sortBy: string | null, { signal }: AbortController): Promise<AxiosResponse<EventsResponse>> => {
  return axiosInstance.get<EventsResponse>(urls.eventsListUrl(sortBy), { signal });
};

export const getEventRequest = (slug: string, { signal }: AbortController): Promise<AxiosResponse<Event>> => {
  return axiosInstance.get<Event>(urls.eventsDetailUrl(slug), { signal });
};
