import styles from 'pages/Patient/FAQ/components/FAQItem.module.scss';
import { renderIcon } from 'app/helpers/icons';
import { Icon } from 'app/types/Icons';
import { Body } from 'components/Typography';

import React from 'react';

interface Props {
  icon: Icon
  title: string;
  text: string;
}

const FAQItem: React.FC<Props> = ({ title, text, icon }) => {
  return (
    <div className={styles.faqItem}>

      {renderIcon(icon, styles.faqItem__icon)}

      <div>

        <Body variant="S"><b>{title}</b></Body>

        <Body variant="S">{text}</Body>

      </div>

    </div>
  );
};

export default FAQItem;
