import 'components/Search/Search.scss';
import { getIconByType } from 'app/helpers/icons';
import usePaginationParams from 'app/hooks/usePaginationParams';

import React, { KeyboardEvent, useState } from 'react';

const Search: React.FC = () => {
  const { searchParam, sortByParam, setFilter } = usePaginationParams();
  const [ value, setValue ] = useState<string>(searchParam);

  const submitFilter = () => setFilter({ search: value, sortBy: sortByParam });

  const onEnterKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      submitFilter();
    }
  };

  return (
    <div className="search">
      <input
        value={value}
        type="search"
        placeholder="Search"
        onChange={e => setValue(e.target.value)}
        onKeyDown={onEnterKeyDown}
      />

      <div className="search__icon" onClick={submitFilter}>{getIconByType('search')}</div>
    </div>
  );
};

export default Search;
