import 'components/ProductBlock/ProductBlock.scss';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import Button from 'components/Button/Button';
import defaultBg from 'components/ProductBlock/assets/bg_block.svg';
import { Body, Heading } from 'components/Typography';

import React, { CSSProperties, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

interface Props {
  title?: string;
  description: string;
  primaryButton: {
    text: string;
    url: string;
  };
  secondaryButton?: {
    text: string;
    url: string;
  };
  background?: string;
  style?: CSSProperties;
  small?: boolean;
  bottomContent?: ReactNode;
}

const ProductBlock: React.FC<Props> = ({
  title,
  description,
  primaryButton,
  secondaryButton,
  background,
  style,
  small = false,
  bottomContent,
}) => {
  const isPatientVersion = useIsPatientVersion();
  const classes = cn('productBlock', { productBlock_small: small, productBlock_patients: isPatientVersion });

  return (
    <div className={classes} style={style}>

      <div className="productBlock__bg" style={{ backgroundImage: `url(${background || defaultBg})` }}>
        {background && <div className="productBlock__bgBlur" />}
      </div>

      {title && <Heading variant="H1" className="productBlock__title">{title}</Heading>}

      <Body variant="L" className="productBlock__description">{description}</Body>

      <div className="productBlock__actions">
        <Link to={primaryButton.url}>
          <Button size="L">{primaryButton.text}</Button>
        </Link>

        {secondaryButton && (
          <Link to={secondaryButton.url}>
            <Button size="L" type="ghost" style={{ marginLeft: 15 }}>{secondaryButton.text}</Button>
          </Link>
        )}
      </div>

      {bottomContent && <div className="productBlock__bottomContent">{bottomContent}</div>}

    </div>
  );
};

export default ProductBlock;
