import styles from 'pages/Patient/HowItWorks/components/HowItWorksItem/HowItWorksItem.module.scss';

import React from 'react';

interface Props {
  children: React.ReactNode;
  image: string;
}

const HowItWorksItem: React.FC<Props> = ({ children, image }) => {
  return (
    <div className={styles.howItWorksItem}>

      <div className={styles.howItWorksItem__image}>
        <img src={image} alt="how it works" />
      </div>

      {children}

    </div>
  );
};

export default HowItWorksItem;
