import 'pages/GalateaDifference/About/components/OurPublications/OurPublications.scss';
import usePublications from 'app/hooks/usePublications';
import urls from 'app/consts/routing';
import Loader from 'components/Loader/Loader';
import { Body, Heading } from 'components/Typography';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OurPublications: React.FC = () => {
  const { t } = useTranslation();
  const { fetchPublications, publications, isLoading } = usePublications();

  useEffect(() => {
    const abortController = new AbortController();
    fetchPublications('', '', abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchPublications ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="ourPublications">
      <div className="ourPublications__topLine">
        <Heading variant="H4">{t('welcomePage.ourPublications')}</Heading>

        <Link to={urls.publications}><Heading variant="H6">{t('welcomePage.showAll')}</Heading></Link>
      </div>

      <div className="ourPublications__links">

        {publications.items.slice(0, 4).map(item => (
          <Link to={urls.publicationsDetailUrl(item.meta.slug)} className="ourPublications__link" key={item.id}>
            <Body variant="M">{item.title}</Body>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default OurPublications;
