import 'components/Header/components/LanguageSwitcher/LanguageSwitcher.scss';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { Caption } from 'components/Typography';

import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const LanguageSwitcher: React.FC = () => {
  const isPatientVersion = useIsPatientVersion();
  const { t, i18n } = useTranslation();

  const switchLanguage = (e: React.ChangeEvent<HTMLInputElement>) => {
    i18n.changeLanguage(!e.target.checked ? 'en' : 'es');
  };

  return (
    <div className={cn('languageSwitcher', { languageSwitcher_patients: isPatientVersion })}>

      <input type="checkbox" checked={i18n.language === 'es'} className="languageSwitcher__checkbox" onChange={switchLanguage} />

      <span className="languageSwitcher__underlay" />

      <span className={cn('languageSwitcher__knob', { languageSwitcher__knob_patients: isPatientVersion })}>
        <Caption variant="XS">{t('general.en')}</Caption>
      </span>

      <span className={cn('languageSwitcher__knob', 'languageSwitcher__knob_right', { languageSwitcher__knob_patients: isPatientVersion })}>
        <Caption variant="XS">{t('general.es')}</Caption>
      </span>

    </div>
  );
};

export default LanguageSwitcher;
