import 'components/VideoBackground/VideoBackground.scss';

import React from 'react';
import cn from 'classnames';

interface Props {
  video: string;
  className?: string;
}

const VideoBackground: React.FC<Props> = ({ video, className }) => (
  <div className={cn('videoBackground', className)}>
    <video playsInline autoPlay muted loop>
      <source src={video} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
);

export default VideoBackground;
