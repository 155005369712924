import 'components/GenesList/GenesList.scss';
import { Gene as GeneType } from 'api/TestingPanels/types';
import Button from 'components/Button/Button';
import Gene from 'components/Gene/Gene';

import React, { CSSProperties, useState } from 'react';

interface Props {
  genes: GeneType[];
  showMoreButton?: boolean;
  maximumGenes?: number;
  style?: CSSProperties;
}

const GenesList: React.FC<Props> = ({ genes, showMoreButton = true, maximumGenes = 20, style }) => {
  const genesAmount = genes.length;
  const [ showAllGenes, setShowAllGenes ] = useState<boolean>(false);
  const displayAllGenes = () => setShowAllGenes(true);

  if (genesAmount === 0) {
    return null;
  }

  return (
    <div className="genesList" style={style}>
      {!showAllGenes ?
        genes.slice(0, maximumGenes).map((gene: GeneType) => <Gene code={gene.name} key={gene.name} />) :
        genes.map((gene: GeneType) => <Gene code={gene.name} key={gene.name} />)}
      {showMoreButton && ((genesAmount > maximumGenes) && !showAllGenes) && (
        <Button
          size="S"
          type="ghost"
          onClick={displayAllGenes}
          className="genesList__moreButton"
          style={{ height: 36 }}
        >
          +{genesAmount - maximumGenes} more
        </Button>
      )}
    </div>
  );
};

export default GenesList;
