import 'components/PageHeader/PageHeader.scss';
import { BreadcrumbItem, SidebarItem } from 'app/types/ProductCatalog';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { Heading } from 'components/Typography';
import Sidebar from 'components/Sidebar/Sidebar';

import React, { ReactNode } from 'react';

interface Props {
  title?: string;
  breadcrumbItems?: BreadcrumbItem[];
  sidebarItems?: SidebarItem[];
  rightColumn?: ReactNode;
}

const PageHeader: React.FC<Props> = ({ title, breadcrumbItems, sidebarItems, rightColumn }) => {
  return (
    <>
      {(title || breadcrumbItems || rightColumn) && (
        <div className="pageHeader">

          <div className="pageHeader__leftColumn">
            {title && <Heading variant="H2">{title}</Heading>}

            {breadcrumbItems && <Breadcrumb items={breadcrumbItems} />}
          </div>

          {rightColumn && <div className="pageHeader__rightColumn">{rightColumn}</div>}

        </div>
      )}

      {sidebarItems && <div className="pageHeader__sidebar"><Sidebar sidebarItems={sidebarItems} /><div className="pageHeader__blur" /></div>}
    </>
  );
};

export default PageHeader;
