import { getFormsAndGuidesRequest } from 'api/Documents/api';
import { FormsAndGuidesResponse } from 'api/Documents/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useState } from 'react';

const useResources = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();
  const [ resources, setResources ] = useState<FormsAndGuidesResponse>({
    forms: [],
    guides: [],
  });

  const fetchResources = useCallback((abortController: AbortController) => {
    setIsLoading(true);
    setError(undefined);

    getFormsAndGuidesRequest(abortController)
      .then(({ data }) => {
        setResources(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          setError(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, []);

  return { resources, isLoading, error, fetchResources };
};

export default useResources;
