import 'components/Forms/Form.scss';
import Button from 'components/Button/Button';
import FormInput from 'components/Forms/components/FormControls/FormInput';
import FormTextArea from 'components/Forms/components/FormControls/FormTextArea';
import useSubmitForm from 'components/Forms/hooks/useSubmitEventForm';
import { Heading } from 'components/Typography';

import React from 'react';
import { useForm } from 'react-hook-form';

export interface EventFormValues {
  firstname: string;
  lastname: string;
  company_email: string;
  company: string;
  product_or_research: string;
}

const EventForm: React.FC = () => {
  const { submitForm, result } = useSubmitForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EventFormValues>();

  return (
    <form className="form">
      <div className="form__block">
        <Heading variant="H6">Basic information</Heading>

        <div className="form__line">
          <FormInput name="firstname" label="First Name" register={register} errors={errors} />

          <FormInput name="lastname" label="Last Name" register={register} errors={errors} />
        </div>

        <FormInput name="company_email" label="Company Email" register={register} errors={errors} />

        <FormInput name="company" label="Company" register={register} errors={errors} />

        <FormTextArea
          name="product_or_research"
          register={register}
          label="What product or research are you interested in learning more about?"
          errors={errors}
          required
        />
      </div>

      {result && <div className="form__result">{result}</div>}

      <Button onClick={handleSubmit(submitForm)} className="form__button" size="L">Send request</Button>
    </form>
  );
};

export default EventForm;
