const publicationsUrl = 'publications/';
const publicationsListUrl = (sortBy: string | null, search: string) => {
  return [
    `${publicationsUrl}?`,
    sortBy && `&tags=${sortBy}`,
    search && `&search=${search}`,
  ].join('');
};
const publicationsDetailUrl = (slug: string) => `${publicationsUrl}${slug}/`;

export default { publicationsListUrl, publicationsDetailUrl };
