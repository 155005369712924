import styles from 'pages/GalateaDifference/Events/components/Event/Event.module.scss';
import urls from 'app/consts/routing';
import { getDateInfo } from 'app/helpers/dateTime';
import { renderIcon } from 'app/helpers/icons';
import useInViewRefs from 'app/hooks/useInViewRefs';
import { BreadcrumbItem, Page, SectionItem, SidebarItem } from 'app/types/ProductCatalog';
import BodyItem from 'components/BodyItem/BodyItem';
import EventForm from 'components/Forms/EventForm';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import { Body, Heading } from 'components/Typography';
import AddressAndDate from 'pages/GalateaDifference/Events/components/Event/components/AddressAndDate';
import useEvent from 'pages/GalateaDifference/Events/components/Event/hooks/useEvent';

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';

const Event: React.FC<Page> = ({ setPageHeader }) => {
  const { eventSlug } = useParams<{ eventSlug: string }>();
  const { t } = useTranslation();
  const { fetchEvent, event, isLoading } = useEvent();
  const startDateInfo = getDateInfo(event.startDate);
  const endDateInfo = getDateInfo(event.endDate);

  const mapCenter = useMemo(() => {
    const location = { lat: 0, lng: 0 };
    const coordinatesFromString = event.location.match('(?<=\\().+?(?=\\))');

    if (coordinatesFromString) {
      const coordinates = coordinatesFromString[0].split(' ');
      location.lng = Number(coordinates[0]);
      location.lat = Number(coordinates[1]);
    }

    return location;
  }, [ event.location ]);

  const inViewRefs = useInViewRefs();
  const firstMenuText = 'About';
  const secondMenuText = 'Location and date';
  const thirdMenuText = 'Schedule a meeting';
  const sectionItems: SectionItem[] = [
    { id: firstMenuText, ref: inViewRefs[0].ref },
    { id: secondMenuText, ref: inViewRefs[1].ref },
    { id: thirdMenuText, ref: inViewRefs[2].ref },
  ];
  const sidebarItems: SidebarItem[] = useMemo(() => [
    { text: firstMenuText, inView: inViewRefs[0].inView },
    { text: secondMenuText, inView: inViewRefs[1].inView },
    { text: thirdMenuText, inView: inViewRefs[2].inView },
  ], [ inViewRefs ]);

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: t('menuItems.galateaDifference'), link: urls.galateaDifference },
    { text: t('menuItems.events'), link: urls.events },
    { text: event.title },
  ], [ t, event.title ]);

  useEffect(() => {
    const abortController = new AbortController();
    if (eventSlug) {
      fetchEvent(eventSlug, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [ fetchEvent, eventSlug ]);

  useEffect(() => {
    if (!isLoading) {
      setPageHeader(<PageHeader title={event.title} breadcrumbItems={breadcrumbItems} rightColumn={<AddressAndDate event={event} />} />);
    } else {
      setPageHeader(undefined);
    }
  }, [ setPageHeader, breadcrumbItems, isLoading, event ]);

  if (isLoading) {
    return <Loader />;
  }

  const renderEventContent = () => (
    <>
      <div id={sectionItems[0].id} ref={sectionItems[0].ref} className={styles.event__section}>
        <Heading variant="H2" className={styles.event__title}>{event.title}</Heading>
        {event.body.map(item => <BodyItem bodyItem={item} key={item.id} />)}
      </div>

      <div id={sectionItems[1].id} ref={sectionItems[1].ref} className={styles.event__section}>

        <Heading variant="H4" style={{ marginBottom: 34 }}>{secondMenuText}</Heading>

        <div className={styles.event__contacts}>

          <div className={styles.event__locationAndDate}>

            <div className={styles.event__info}>

              <div className={styles.event__column}>
                {renderIcon('calendar', styles.event__icon)}
                <Body variant="S">{startDateInfo.day} - {endDateInfo.day}, {startDateInfo.month} {startDateInfo.year}</Body>
              </div>

              {event.eventUrl && (
                <div className={styles.event__column}>
                  {renderIcon('computer', styles.event__icon)}
                  <Body variant="S">
                    <Link to={event.eventUrl} target="_blank" rel="noopener noreferrer">Event reference</Link>
                  </Body>
                </div>
              )}

            </div>

            <div>
              {event.addressPlace && <Heading variant="H5" style={{ marginBottom: 13 }}>{event.addressPlace}</Heading>}
              <Body variant="M">{event.address}</Body>
            </div>

          </div>

          <div className={styles.event__mapWrapper}>
            <Map
              className={styles.event__map}
              defaultCenter={mapCenter}
              defaultZoom={event.zoom}
              gestureHandling="cooperative"
              disableDefaultUI
            >
              <Marker position={mapCenter} />
            </Map>
          </div>

        </div>

      </div>

      <div id={sectionItems[2].id} ref={sectionItems[2].ref} className={styles.event__section}>
        <Heading variant="H4" style={{ marginBottom: 41 }}>{thirdMenuText}</Heading>
        <EventForm />
      </div>
    </>
  );

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}>
      <div className={styles.event}>

        <PageWithSidebar sidebarItems={sidebarItems} content={renderEventContent()} />

      </div>
    </APIProvider>
  );
};

export default Event;
