import styles from 'pages/Provider/OrderTest/OrderTest.module.scss';
import { ReactComponent as Logo } from 'app/assets/Logo.svg';
import useBuiltInDocs from 'app/hooks/useBuiltInDocs';
import useInViewRefs from 'app/hooks/useInViewRefs';
import { SectionItem, Page, SidebarItem } from 'app/types/ProductCatalog';
import Button from 'components/Button/Button';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import SampleCollectionInstructions from 'components/SampleCollectionInstructions/SampleCollectionInstructions';
import { Body, Caption, Heading } from 'components/Typography';
import OrderTestItem from 'pages/Provider/OrderTest/components/OrderTestItem/OrderTestItem';

import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

const OrderTest: React.FC<Page> = ({ setPageHeader }) => {
  const { fetchDocs, docs } = useBuiltInDocs();
  const inViewRefs = useInViewRefs();
  const firstMenuText = 'Order a Test on ELIS / LIMS';
  const secondMenuText = 'Collection Instructions';
  const sectionItems: SectionItem[] = [
    { id: firstMenuText, ref: inViewRefs[0].ref },
    { id: secondMenuText, ref: inViewRefs[1].ref },
  ];
  const sidebarItems: SidebarItem[] = useMemo(() => [
    { text: firstMenuText, inView: inViewRefs[0].inView },
    { text: secondMenuText, inView: inViewRefs[1].inView },
  ], [ inViewRefs ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchDocs(abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchDocs ]);

  const guide = useMemo(() => docs.find(doc => doc.documentType === 'Provider_OrderTest'), [ docs ]);

  const renderOrderTestContent = () => {
    return (
      <>
        <div id={sectionItems[0].id} ref={sectionItems[0].ref}>

          <Heading variant="H3" className={styles.orderTest__title}>{firstMenuText}</Heading>

          <div className={styles.orderTest__elis}>

            <div className={styles.orderTest__items}>
              <OrderTestItem title="Authorization on ELIS/LIMS portal" icon="finger-access" />
              <OrderTestItem title="Creating New Order" icon="shopping-cart-add" />
              <OrderTestItem title="Adding patient basic information" icon="contact" />
              <OrderTestItem title="Selecting panels" icon="lab" />
              <OrderTestItem title="Adding necessary medical information" icon="contracts" />
              <OrderTestItem title="Adding order details (shipping, notes, documents, etc.)" icon="contracts" />
              <OrderTestItem title="Send order and review progress in your account on Elis portal" icon="mail" />
            </div>

            <div className={styles.orderTest__download}>

              <Heading variant="H5">Download our detailed guide</Heading>

              <div className={styles.orderTest__guide}>
                <Logo className={styles.orderTest__logo} />

                <Caption variant="L">How to order a Test on ELIS/LIMS</Caption>

                <Body variant="S">Full step-by-step guide</Body>
              </div>

              <Link to={guide?.documentUrl || '#'} className={styles.orderTest__button} target="_blank" rel="noopener noreferrer">
                <Button size="L">Download</Button>
              </Link>

            </div>

          </div>

        </div>

        <div id={sectionItems[1].id} ref={sectionItems[1].ref}>
          <SampleCollectionInstructions />
        </div>
      </>
    );
  };

  useEffect(() => setPageHeader(<PageHeader sidebarItems={sidebarItems} />), [ setPageHeader, sidebarItems ]);

  return (
    <div className={styles.orderTest}>

      <PageWithSidebar sidebarItems={sidebarItems} content={renderOrderTestContent()} />

    </div>
  );
};

export default OrderTest;
