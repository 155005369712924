import styles from 'pages/Provider/HowItWorks/components/HowItWorksItem/HowItWorksItem.module.scss';
import { getIconByType, renderIcon } from 'app/helpers/icons';
import { Icon } from 'app/types/Icons';
import { Heading } from 'components/Typography';

import React from 'react';

interface Props {
  title: string;
  icon: Icon;
}

const HowItWorksItem: React.FC<Props> = ({ title, icon }) => {
  return (
    <div className={styles.howItWorksItem}>

      {getIconByType(icon)}

      {renderIcon('arrow', styles.howItWorksItem__arrow)}

      <Heading variant="H6">{title}</Heading>

    </div>
  );
};

export default HowItWorksItem;
