import urls from 'app/consts/routing';
import useInViewRefs from 'app/hooks/useInViewRefs';
import { SectionItem, SidebarItem, BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import HealthRiskScoring from 'pages/ProductCatalog/components/Product/components/HealthRiskScoring/HealthRiskScoring';
import TrioAnalysis from 'pages/ProductCatalog/components/Product/components/TrioAnalysis/TrioAnalysis';
import useProduct from 'pages/ProductCatalog/components/Product/hooks/useProduct';

import React, { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

const Product: React.FC<Page> = ({ setPageHeader }) => {
  const { productId } = useParams<{ productId: string }>();
  const { fetchProduct, product, isLoading } = useProduct();
  const { title, productType, secondaryButtonText } = product;
  const isPrs = productType === 'PRS';

  useEffect(() => {
    const abortController = new AbortController();
    if (productId) {
      fetchProduct(productId, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [ fetchProduct, productId ]);

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: 'Products', link: urls.products },
    { text: title },
  ], [ title ]);

  const inViewRefs = useInViewRefs();
  const firstMenuText = isPrs ? 'What is a PRS?' : 'Genome vs Exome';
  const secondMenuText = isPrs ? 'Clinical advantages' : 'Key Factors';
  const thirdMenuText = isPrs ? 'How Galatea does it?' : 'Analysis Comparing';
  const sectionItems: SectionItem[] = [
    { id: firstMenuText, ref: inViewRefs[0].ref },
    { id: secondMenuText, ref: inViewRefs[1].ref },
    { id: thirdMenuText, ref: inViewRefs[2].ref },
  ];
  const sidebarItems: SidebarItem[] = useMemo(() => [
    { text: firstMenuText, inView: inViewRefs[0].inView },
    { text: secondMenuText, inView: inViewRefs[1].inView },
    { text: thirdMenuText, inView: inViewRefs[2].inView },
  ], [ inViewRefs, firstMenuText, secondMenuText, thirdMenuText ]);

  const TestingCatalogButton = useMemo(() => (
    <Link to={urls.panelsWithSort(productType)}>
      <Button size="L" type="ghost">{secondaryButtonText}</Button>
    </Link>
  ), [ productType, secondaryButtonText ]);

  const renderProductContent = () => {
    if (isPrs) {
      return <HealthRiskScoring sectionItems={sectionItems} />;
    } else {
      return <TrioAnalysis sectionItems={sectionItems} />;
    }
  };

  useEffect(() => {
    setPageHeader(<PageHeader title={title} sidebarItems={sidebarItems} breadcrumbItems={breadcrumbItems} rightColumn={TestingCatalogButton} />);
  }, [ setPageHeader, breadcrumbItems, TestingCatalogButton, sidebarItems, title ]);

  if (isLoading) {
    return <Loader />;
  }

  return <PageWithSidebar sidebarItems={sidebarItems} content={renderProductContent()} />;
};

export default Product;
