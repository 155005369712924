import 'pages/GalateaDifference/About/About.scss';
import { Page } from 'app/types/ProductCatalog';
import { Heading } from 'components/Typography';
import AboutLink from 'pages/GalateaDifference/About/components/AboutLink/AboutLink';
import AboutUs from 'pages/GalateaDifference/About/components/AboutUs/AboutUs';
import LatestEvents from 'pages/GalateaDifference/About/components/LatestEvents/LatestEvents';
import OurPublications from 'pages/GalateaDifference/About/components/OurPublications/OurPublications';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const About: React.FC<Page> = ({ setPageHeader }) => {
  const { t } = useTranslation();

  useEffect(() => setPageHeader(undefined));

  return (
    <div className="about">

      <AboutUs />

      <div className="about__bottomPanel">

        <div>

          <Heading variant="H2" className="about__title">{t('galateaDifference.scienceAndResearch')}</Heading>

          <div className="about__links">
            <AboutLink text={t('galateaDifference.searchByPublications')} icon="search" linkTo="/" />
            <AboutLink text={t('galateaDifference.offlineEvents')} icon="calendar" linkTo="/" />
            <AboutLink text={t('galateaDifference.webinars')} icon="computer" linkTo="/" />
          </div>

        </div>

        <OurPublications />

        <LatestEvents />

      </div>

    </div>
  );
};

export default About;
