import 'components/CatalogPanel/CatalogPanel.scss';
import { Subset as SubsetType, TestingPanelCatalogItem } from 'api/TestingPanels/types';
import { grayscale400 } from 'app/consts/colors';
import { renderIcon } from 'app/helpers/icons';
import { pluralize } from 'app/helpers/pluralize';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import Button from 'components/Button/Button';
import CardiometabolicConditions from 'components/CardiometabolicConditions/CardiometabolicConditions';
import Subset from 'components/Subset/Subset';
import { Caption, Heading } from 'components/Typography';

import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

interface Props {
  panel: TestingPanelCatalogItem;
}

const CatalogPanel: React.FC<Props> = ({ panel }) => {
  const {
    productType,
    title,
    genesAmount,
    subsetIcons,
    relatedDocuments,
    subsets,
    meta: { slug },
    sampleReport,
  } = panel;
  const isPatientVersion = useIsPatientVersion();
  const isGeneral = productType === null;
  const isPrs = productType === 'PRS';

  return (
    <div className="catalogPanel">

      <div className="catalogPanel__left">

        <div className="catalogPanel__topLine">

          <div className="catalogPanel__navigation">
            <Heading variant="H3" className={cn('catalogPanel__title', { catalogPanel__title_patients: isPatientVersion })}>
              {title}
            </Heading>

            <Link to={slug}>
              <Button size="S">Show details</Button>
            </Link>
          </div>

          { isGeneral && (
            <div className="catalogPanel__genes">
              <Heading variant="H1">{genesAmount}</Heading>
              <Caption variant="XS" style={{ color: grayscale400 }}>Genes in total</Caption>
            </div>
          )}

        </div>

        <div className="catalogPanel__bottomLine">

          <div className="catalogPanel__icons">
            {!isPrs && subsetIcons.map(icon => renderIcon(icon))}
          </div>

          {isPrs && sampleReport && (
            <Link to={sampleReport} className="catalogPanel__docs catalogPanel__docs_sampleReport" target="_blank" rel="noopener noreferrer">
              {renderIcon('file')}
              <Caption variant="XS" className={cn('catalogPanel__docsCount', { catalogPanel__docsCount_patients: isPatientVersion })}>
                Sample Report
              </Caption>
            </Link>
          )}

          {isGeneral && relatedDocuments > 0 && (
            <div className="catalogPanel__docs">
              {renderIcon('file')}
              <Caption variant="XS" className={cn('catalogPanel__docsCount', { catalogPanel__docsCount_patients: isPatientVersion })}>
                {pluralize(relatedDocuments, 'Related Document')}
              </Caption>
            </div>
          )}

        </div>

      </div>

      <div className="catalogPanel__content">

        <Heading variant="H5" className="catalogPanel__contentTitle">
          {isPrs ? 'Cardiometabolic PRS for 8 conditions' : `Subsets (${subsets.length})`}
        </Heading>

        {isPrs ? (
          <div className="catalogPanel__conditions">
            <CardiometabolicConditions />
          </div>
        ) : (
          <div className="catalogPanel__subsets">
            {subsets.map((subset: SubsetType) => <Subset subset={subset} panelSlug={slug} key={subset.title} />)}
          </div>
        )}
      </div>

    </div>
  );
};

export default CatalogPanel;
