import styles from 'pages/WelcomePage/components/Publications/Publications.module.scss';
import urls from 'app/consts/routing';
import usePublications from 'app/hooks/usePublications';
import { Body, Heading } from 'components/Typography';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  headerText: string;
  linkTo: string
}

const Publications: React.FC<Props> = ({ headerText, linkTo }) => {
  const { t } = useTranslation();
  const { fetchPublications, publications } = usePublications();

  useEffect(() => {
    const abortController = new AbortController();
    fetchPublications('', '', abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchPublications ]);

  return (
    <div className={styles.publications}>

      <div>

        <div className={styles.publications__header}>
          <Heading>{headerText}</Heading>
        </div>

        <div className={styles.publications__list}>

          {publications.items.slice(0, 3).map(item => (
            <Link to={urls.publicationsDetailUrl(item.meta.slug)} className={styles.publications__listItem} key={item.id}>
              <Body variant="M" className={styles.publications__link}>{item.title}</Body>
            </Link>
          ))}

        </div>

      </div>

      <div>

        <div className={styles.publications__separator} />

        <Link to={linkTo} className={styles.publications__showAll}>
          <Body className={styles.publications__link} variant="L">{t('welcomePage.showAll')}</Body>
        </Link>

      </div>

    </div>
  );
};

export default Publications;
