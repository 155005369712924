import { axiosInstance } from 'api/instance';
import { TestingPanelsResponse, TestingPanel, ComparingResponse } from 'api/TestingPanels/types';
import urls from 'api/TestingPanels/urls';

import { AxiosResponse } from 'axios';

export const getTestingPanelsRequest = (
  sortBy: string | null,
  search: string,
  { signal }: AbortController,
): Promise<AxiosResponse<TestingPanelsResponse>> => {
  return axiosInstance.get<TestingPanelsResponse>(urls.testingPanelsListUrl(sortBy, search), { signal });
};

export const getTestingPanelRequest = (slug: string, { signal }: AbortController): Promise<AxiosResponse<TestingPanel>> => {
  return axiosInstance.get<TestingPanel>(urls.testingPanelsDetailUrl(slug), { signal });
};

export const getComparingRequest = (panelSlug: string, subsetSlug: string, { signal }: AbortController): Promise<AxiosResponse<ComparingResponse>> => {
  return axiosInstance.get<ComparingResponse>(urls.comparingUrl(panelSlug, subsetSlug), { signal });
};
