import styles from 'components/CollapsableBlock/CollapsableBlock.module.scss';
import { renderIcon } from 'app/helpers/icons';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { Heading } from 'components/Typography';

import React, { ReactNode, useState } from 'react';
import cn from 'classnames';

interface Props {
  title: string;
  children: ReactNode;
}

const CollapsableBlock: React.FC<Props> = ({ title, children }) => {
  const isPatientVersion = useIsPatientVersion();
  const [ isOpened, setIsOpened ] = useState<boolean>(false);

  const collapse = () => setIsOpened(prevState => !prevState);

  return (
    <div className={styles.collapsableBlock}>

      <div className={cn(styles.collapsableBlock__header, { [styles.collapsableBlock__header_patients]: isPatientVersion })}>
        <div onClick={collapse}>
          <Heading variant="H4" className={styles.collapsableBlock__title}>{title}</Heading>
        </div>
        <div onClick={collapse}>
          {renderIcon('chevron-up', cn(styles.collapsableBlock__icon, { [styles.collapsableBlock__icon_opened]: isOpened }))}
        </div>
      </div>

      <div className={cn(styles.collapsableBlock__content, { [styles.collapsableBlock__content_opened]: isOpened })}>
        <div className={styles.collapsableBlock__inner}>{children}</div>
      </div>

    </div>
  );
};

export default CollapsableBlock;
