import { SearchParams, FilterParams } from 'app/types/PaginationParams';

import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

const usePaginationParams = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();

  const searchParam = searchParams.get(SearchParams.SEARCH) || '';
  const sortByParam = searchParams.get(SearchParams.SORT_BY) || null;

  const setFilter = useCallback((params: FilterParams) => {
    if (params.search) {
      searchParams.set(SearchParams.SEARCH, params.search);
    } else {
      searchParams.delete(SearchParams.SEARCH);
    }

    if (params.sortBy) {
      searchParams.set(SearchParams.SORT_BY, params.sortBy);
    } else {
      searchParams.delete(SearchParams.SORT_BY);
    }
    setSearchParams(searchParams, { replace: true });
  }, [ searchParams, setSearchParams ]);

  return {
    searchParam,
    sortByParam,
    setFilter,
  };
};

export default usePaginationParams;
