import { axiosInstance } from 'api/instance';
import { ProductResponse, ProductsResponse } from 'api/Products/types';
import urls from 'api/Products/urls';

import { AxiosResponse } from 'axios';

export const getProductsRequest = ({ signal }: AbortController): Promise<AxiosResponse<ProductsResponse>> => {
  return axiosInstance.get<ProductsResponse>(urls.productsUrl, { signal });
};

export const getProductRequest = (slug: string, { signal }: AbortController): Promise<AxiosResponse<ProductResponse>> => {
  return axiosInstance.get<ProductResponse>(urls.productsDetailUrl(slug), { signal });
};
