import 'pages/ProductCatalog/components/Panel/components/AssayInformation/AssayInformation.scss';
import Button from 'components/Button/Button';
import { Body, Heading } from 'components/Typography';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

interface Props {
  assayInformation: string
}

const AssayInformation: React.FC<Props> = ({ assayInformation }) => {
  const { t } = useTranslation();
  const [ isAssayInformationOpened, setIsAssayInformationOpened ] = useState<boolean>(false);
  const onReadFullClick = () => setIsAssayInformationOpened(true);

  return (
    <>
      <Heading variant="H4">Assay Information</Heading>

      <div className={cn('assayInformation', { assayInformation_opened: isAssayInformationOpened })}>
        <Body variant="M">{assayInformation}</Body>

        {!isAssayInformationOpened && <Button size="L" onClick={onReadFullClick} className="assayInformation__button">{t('general.readFull')}</Button>}

        {!isAssayInformationOpened && <div className="assayInformation__blur" />}
      </div>
    </>
  );
};

export default AssayInformation;
