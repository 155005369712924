import 'pages/ProductCatalog/ProductCatalog.scss';
import { ProductItem } from 'api/Products/types';
import urls from 'app/consts/routing';
import { renderIcon } from 'app/helpers/icons';
import { Page } from 'app/types/ProductCatalog';
import Loader from 'components/Loader/Loader';
import ProductBlock from 'components/ProductBlock/ProductBlock';
import { Caption, Heading } from 'components/Typography';
import useProducts from 'pages/ProductCatalog/hooks/useProducts';

import React, { useEffect } from 'react';

const ProductCatalog: React.FC<Page> = ({ setPageHeader }) => {
  const { fetchProducts, products, isLoading } = useProducts();

  useEffect(() => {
    setPageHeader(undefined);
  }, [ setPageHeader ]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchProducts(abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchProducts ]);

  const GeneticTestingBottomContent = (
    <>
      <div className="productCatalog__genes">
        <Heading variant="H1">{products.commonInfo.genesTotal}</Heading>
        <Caption variant="XS" className="productCatalog__total">Genes in total</Caption>
      </div>
      <div className="productCatalog__icons">
        {products.commonInfo.commonIcons.map(icon => renderIcon(icon))}
      </div>
    </>
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="productCatalog">
      <ProductBlock
        title={products.commonInfo.commonTitle}
        description={products.commonInfo.commonDescription}
        primaryButton={{ text: 'Show Catalog', url: urls.panels }}
        bottomContent={GeneticTestingBottomContent}
        background={products.commonInfo.commonImage}
      />

      {products.items.map((product: ProductItem) => (
        <ProductBlock
          key={product.id}
          title={product.title}
          description={product.productDetails[0].value.detailsInfo}
          primaryButton={{ text: product.primaryButtonText, url: product.meta.slug }}
          secondaryButton={{ text: product.secondaryButtonText, url: product.productType ? urls.panelsWithSort(product.productType) : urls.panels }}
          background={product.productDetails[0].value.detailsImage}
        />
      ))}
    </div>
  );
};

export default ProductCatalog;
