import 'components/Loader/Loader.scss';
import blue from 'components/Loader/assets/spinner_blue.png';
import white from 'components/Loader/assets/spinner_white.png';

import React from 'react';

interface Props {
  color?: 'blue' | 'white';
}

const Loader: React.FC<Props> = ({ color }) => (
  <div className="loader">
    <div className="loader__circle" style={{ backgroundImage: `url(${color === 'white' ? white : blue})` }} />
  </div>
);

export default Loader;
