import 'components/Forms/components/FormControls/FormControl.scss';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { FormTextArea as FormTextAreaType } from 'components/Forms/types';
import { Caption } from 'components/Typography';

import React from 'react';
import cn from 'classnames';
import { FieldValues } from 'react-hook-form';

const FormTextArea = <T extends FieldValues>({ name, register, label, required, errors }: FormTextAreaType<T>) => {
  const isPatientVersion = useIsPatientVersion();
  const textAreaClasses = cn(
    'formControl__input',
    'formControl__input_textarea',
    { formControl__input_patients: isPatientVersion, formControl__input_invalid: !!errors[name] },
  );

  return (
    <label htmlFor={name} className="formControl">
      {label && <Caption variant="XS">{label}</Caption>}

      <textarea className={textAreaClasses} id={name} {...register(name, { required: required ? 'Field is required' : false })} />

      {!!errors[name] && <Caption className="formControl__error" variant="XS">{errors[name]?.message as string}</Caption>}
    </label>
  );
};

export default FormTextArea;
