import { getProductsRequest } from 'api/Products/api';
import { ProductsResponse } from 'api/Products/types';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';

import { useCallback, useState } from 'react';

const useProducts = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();
  const [ products, setProducts ] = useState<ProductsResponse>({
    meta: { totalCount: 0 },
    items: [],
    commonInfo: {
      genesTotal: 0,
      commonTitle: '',
      commonDescription: '',
      commonIcons: [],
      commonImage: '',
    },
  });

  const fetchProducts = useCallback((abortController: AbortController) => {
    setIsLoading(true);
    setError(undefined);

    getProductsRequest(abortController)
      .then(({ data }) => {
        setProducts(data);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          setError(getErrorFromPromiseReason(reason));
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setIsLoading(false);
        }
      });
  }, []);

  return { products, isLoading, error, fetchProducts };
};

export default useProducts;
