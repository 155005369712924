import 'components/Header/components/TopMenu/TopMenu.scss';
import urls from 'app/consts/routing';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { Caption } from 'components/Typography';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const TopMenu: React.FC = () => {
  const { t } = useTranslation();
  const isProviders = !useIsPatientVersion();
  const targetActionLink = isProviders ? urls.orderTest : urls.submitSample;
  const targetActionLinkWidth = isProviders ? 72 : 104;
  const targetActionLinkText = isProviders ? t('general.orderTest') : t('general.submitSample');
  const howItWorksLink = isProviders ? urls.howItWorksProviders : urls.howItWorksPatients;
  const resourcesLink = isProviders ? urls.resources : urls.faq;

  return (
    <div className="topMenu">

      {/* <div className="topMenu__blur" /> */}

      <NavLink className="topMenu__link" to={howItWorksLink}>
        <Caption variant="L">{t('menuItems.howItWorks')}</Caption>
      </NavLink>

      <NavLink className="topMenu__link" to={targetActionLink} style={{ width: targetActionLinkWidth }}>
        <Caption variant="L">{targetActionLinkText}</Caption>
      </NavLink>

      <NavLink className="topMenu__link" to={resourcesLink}>
        <Caption variant="L">{t('menuItems.resources')}</Caption>
      </NavLink>

      <NavLink className="topMenu__link" to={urls.products}>
        <Caption variant="L">{t('menuItems.productCatalog')}</Caption>
      </NavLink>

      <NavLink className="topMenu__link" to={urls.galateaDifference}>
        <Caption variant="L">{t('menuItems.galateaDifference')}</Caption>
      </NavLink>

    </div>
  );
};

export default TopMenu;
