import { postSubmitFormRequest } from 'api/Hubspot/api';
import { HubspotFormField } from 'api/Hubspot/types';
import urls from 'api/Hubspot/urls';
import getErrorFromPromiseReason from 'app/helpers/getErrorFromPromiseReason';
import { getHubspotFields } from 'components/Forms/helpers';
import { EventFormValues } from 'components/Forms/EventForm';

import { SubmitHandler } from 'react-hook-form';
import { useState } from 'react';

const useSubmitEventForm = () => {
  const [ result, setResult ] = useState<string>('');

  const submitForm: SubmitHandler<EventFormValues> = (data) => {
    setResult('');
    const fields: HubspotFormField[] = getHubspotFields(data);

    postSubmitFormRequest(urls.eventFormUrl, fields)
      .then(({ data }) => {
        setResult(data.inlineMessage || 'The form was submitted successfully');
      })
      .catch((reason) => {
        setResult(getErrorFromPromiseReason(reason));
      });
  };

  return { submitForm, result };
};

export default useSubmitEventForm;
